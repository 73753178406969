<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <img
        class="img-rounded"
        src="../assets/ALISU_logo.png"
        alt="tech_origin_class_image"
        style="height: 37px; padding: 0px 3px 4px 5px"
      />
      <routerLink class="navbar-brand fw-bold display-5" to="/" id="titlee"
        >TechOrigin</routerLink
      >
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#main-nav"
        aria-controls="main-nav"
        aria-expanded="true"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div
        class="collapse navbar-collapse justify-content-end align-center"
        id="main-nav"
      >
        <ul class="navbar-nav">
          <li class="nav-item me-4 py-2 menufontfamily menuItem">
            <routerLink class="topp" to="/">Home</routerLink>
          </li>
          <li class="nav-item me-2 py-2 menufontfamily menuItem">
            <a class="topp" href="#courses">Resources</a>
          </li>
          <li class="nav-item me-4 py-2 menufontfamily menuItem">
            <routerLink class="topp" to="/Review">Write A Review</routerLink>
          </li> 
          <li class="nav-item me-4 py-2 menufontfamily menuItem">
            <routerLink class="topp" to="/verify">Verify Certificate</routerLink>
          </li> 
        </ul>
      </div>
    </div>
    <center>
      <div class="col-sm-11"><hr /></div>
    </center>
  </nav>
</template>

<script>
export default {
  name: "Heading_bar",
};
</script>

<style scoped>
.menudropdown{
    font-size: 15px;
  }
.topp {
  text-decoration: none;
  color: rgb(8, 103, 136);
}
.navbar-light {
  background-color: rgb(8, 103, 136);
}
img {
  background: none;
}
#titlee {
  color: rgb(8, 103, 136);
}
#navbarDarkDropdownMenuLink {
  color: rgb(8, 103, 136);
}
.menufontfamily {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.menuItem {
  background-image: linear-gradient(
    to right,
    #086788,
    #086788 50%,
    #086788 50%
  );
  background-size: 200% 100%;
  background-position: -100%;
  display: inline-block;
  padding: 5px 0;
  position: relative;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: all 0.3s ease-in-out;
}

.menuItem:before {
  content: "";
  background: #086788;
  display: block;
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  transition: all 0.3s ease-in-out;
}

.menuItem:hover {
  background-position: 0;
}

.menuItem:hover::before {
  width: 100%;
}
@media only screen and (min-width: 992px) {
  .dropdown-menu {
    transform: translateX(-40px);
}
} 
</style>