<template>
 <head>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap" rel="stylesheet">
    <link href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo" rel="stylesheet"
      type="text/css" />
  </head>
<Headingbar />
<section id="intro"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
<center>
      <div class="col-md-9 justify-content-center">
        <h1 id="par" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin:40px 0px 0px 0px;">
          Our Programs</h1>
        <div style="justify-content: center; width: 80px;background-color: rgb(34, 116, 143);padding: 2px;margin:15px 0px 30px 0px;"></div>
        <p class="context fw-bold">
          TechOrigin is on a mission to quicken the pace at which Africa expecially Nigeria develops sustainable technology by itself to tackle prevailing local technological needs in Nigeria thereby leading to the creation of more jobs and advancing of our technological landscape. <br><br> In other to achieve this we have created 4 major programs capable of equipping the young population in Nigeria first to learn relevant entreprenerial & technological skill and then create technology based solutions to solve strategic local problems</p>
      </div>
</center>
</section>
<section id="Technology School" class="bg-light body"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
<center>
      <div class="col-md-9 justify-content-center">
        <h1 id="par" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin:40px 0px 0px 0px;">
          Technology School</h1>
        <div style="justify-content: center; width: 80px;background-color: rgb(34, 116, 143);padding: 2px;margin:15px 0px 30px 0px;"></div>
        <p class="context fw-bold">
          Technology school is a 3 month quarterly training with focused technology skill development targeted at ensuring all our participant come to full mastery of major prevailing technological stacks especially Data Analytics and Software Development.</p>
          <p class="context fw-bold">We organise 3 Technology School yearly (February, June & October) that trains 20 students per batch on 3 core technology development courses with an hybrid lecture approach (Physical & virtual) that includes assignment, project presentation and certification.</p>
      </div>
</center>
</section>
<section id="Short Courses"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
<center>
      <div class="col-md-9 justify-content-center">
        <h1 id="par" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin:40px 0px 0px 0px;">
          Short Courses</h1>
        <div style="justify-content: center; width: 80px;background-color: rgb(34, 116, 143);padding: 2px;margin:15px 0px 30px 0px;"></div>
        <p class="context fw-bold">
          TechOrigin short courses are a two week long training program that is relevant for young professionals seeking to advance their digital skills to help improve their productivity and deliverables in their work place.</p>
        <p class="context fw-bold">
          Our short courses are fully virtual & centered strategically not to affect working hours while ensuring our participant also learn efficiently. We organise short courses bi-monthly on specific technology based skills and its usually announced on our social media handles or those connected to us via mails.</p>
      </div>
</center>
</section>
<section id="Private Classes" class="bg-light body"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
<center>
      <div class="col-md-9 justify-content-center">
        <h1 id="par" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin:40px 0px 0px 0px;">
          Private Classes</h1>
        <div style="justify-content: center; width: 80px;background-color: rgb(34, 116, 143);padding: 2px;margin:15px 0px 30px 0px;"></div>
        <p class="context fw-bold">
          TechOrigin private classes seeks to attend to training participants that may want to be trained at their own time and pace with the same course work as handled in Technology School. </p>
        <p class="context fw-bold">
          Our private classes are organised either virtually or physically depending on the participant and we use the same curriculum as Technology School in teaching to ensure our training participant come up to mastery.</p>
        <p class="context fw-bold">
         Private classes are very affordable and the pace is not generic for all participants. You learn at your convenience.</p>
      </div>
</center>
</section>
<Base />
</template>


<script>
import Headingbar from '../components/Headingbar.vue'
import Base from '../components/Base.vue'
export default {
  name: 'App', 
  components: {
  Headingbar,
  Base
  }
}
</script>

<style scoped>
#par {
  color: rgb(8, 103, 136);
  font-family: Nunito, sans-serif;
  line-height: 27.2px;
}
.context {
  font-family: Nunito, sans-serif;
  line-height: 27.2px;
  font-weight: 700;
}
.body {
    padding: 50px 0px 50px 0px;
}
</style>
