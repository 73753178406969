<template>
  <head>
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap"
      rel="stylesheet"
    />
    <link
      href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo"
      rel="stylesheet"
      type="text/css"
    />
  </head>
  <Headingbar />

  <section
    id="contactbg"
    data-aos="zoom-in"
    data-aos-duration="2500"
    data-aos-delay="500"
    data-aos-once="true"
  >
    <div class="row">
      <div class="col-md-5">
        <div class="ms-5 my-4">
          <div class="details">
            <p class="par h4 text-light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                class="bi bi-telephone"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"
                /></svg
              >&nbsp; CALL US
            </p>
            <p class="content">07040696229</p>
          </div>
          <div class="details">
            <p class="par h4 text-light">
              <span
                ><svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  fill="currentColor"
                  class="bi bi-geo-alt"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A31.493 31.493 0 0 1 8 14.58a31.481 31.481 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94zM8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10z"
                  />
                  <path
                    d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"
                  />
                </svg> </span
              >&nbsp; LOCATION
            </p>
            <p class="content">
              No. 8 Adele Close, Eliowhani, Rumuodara, Port-Harcourt Nigeria
            </p>
          </div>
          <div class="details">
            <p class="par h4 text-light">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                fill="currentColor"
                class="bi bi-clock-history"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z"
                />
                <path
                  d="M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z"
                />
                <path
                  d="M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z"
                /></svg
              >&nbsp; CLASS HOURS
            </p>
            <p class="content">
              Our classes hold on every fridays from 4:00pm till 6:00pm and on
              saturdays from 10:00am till 6:00pm
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-7 muive">
        <form id="addPost" @submit="onSubmit" class="bg-light p-5">
          <div class="box">
            <p class="h1" id="par">CONTACT US</p>
            <input
              class="inputs"
              title="Your Fullname"
              type="name"
              v-model="name"
              name="name"
              placeholder="Enter your fullname..."
              required
            />
            <div class="mb-3 cover"><div class="effect"></div></div>
          </div>
          <div class="box">
            <input
              class="inputs"
              title="Enter a valid email"
              type="email"
              v-model="email"
              id="email"
              name="email"
              placeholder="Your Email..."
              required
            />
            <div class="mb-3 cover"><div class="effect"></div></div>
          </div>
          <div class="box">
            <textarea
              form="message"
              class="inputs textarea"
              v-model="message"
              placeholder="Your Message"
            ></textarea>
            <div class="mb-3 cover"><div class="effect"></div></div>
          </div>
          <div class="muive">
            <input
              type="submit"
              value="SEND MESSAGE !!!"
              class="btn"
              v-on:click="addComment()"
            />
            <!-- <div class="chat">
        <a href="https://www.linkedin.com/company/techorigin/" target="_blank" class="ps-3">
                    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="rgb(8,103,136)" class="bi bi-whatsapp" viewBox="0 0 16 16">
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
                </svg>
        </a>
      </div> -->
          </div>

          <p class="h1">{{ successful }}</p>
        </form>
      </div>
    </div>
  </section>
  <center>
    <div class="col-sm-12"><hr /></div>
  </center>
  <center>
    <p class="fw-light" id="par">
      Copyright © 2024 TechOrigin Technology Development Limited RC - 2022065
    </p>
  </center>
</template>

<script>
import Headingbar from "../components/Headingbar.vue";
import axios from "axios";
import store from "../shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "../shared/storeconstants";
export default {
  name: "Contact_us",
  components: {
    Headingbar,
  },
  data() {
    return {
      successful: "",
      name: "",
      email: "",
      message: "",
    };
  },
  methods: {
    async addComment(e) {
      e.preventDefault();
      if (!this.name) {
        alert("Please Add a Name");
        return;
      }
      const NewComment = {
        id: Math.floor(Math.random() * 100000),
        name: this.name,
        email: this.email,
        message: this.message,
      };
      store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec?secretKey=ContactUs2022",
          JSON.stringify(NewComment)
        )
        .then(function (response) {
          alert(response.data);
        });
      store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
#contactbg {
  background-color: rgb(8, 103, 136);
  margin: 100px 0px 100px 0px;
  height: 400px;
  width: 100%;
}
#addPost {
  width: 85%;
  height: 500px;
  transform: translateY(-50px);
  border-radius: 10px;
}
#par {
  color: rgb(8, 103, 136);
  line-height: 27.2px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
.par {
  line-height: 27.2px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  letter-spacing: 2px;
}
.content {
  line-height: 27.2px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: rgb(217, 221, 226);
}
svg {
  transform: translateY(-2px);
}
.details {
  margin: 40px 0px 40px 0px;
}
.inputs {
  border: none;
  outline: 0px solid transparent;
  background-color: rgb(248, 249, 250);
  width: 90%;
  margin: 30px 0px 0px 0px;
}
.effect {
  background-color: rgb(8, 103, 136);
  height: 1px;
  width: 0px;
  transition: width 0.8s;
}
.effect {
  transition-timing-function: linear;
}

.box:hover .effect {
  width: 100%;
}
.cover {
  height: 1px;
  width: 90%;
  background-color: rgb(219, 222, 223);
}
.textarea {
  height: 120px;
}
.btn {
  border: 1px solid rgb(8, 103, 136);
  color: rgb(8, 103, 136);
  margin: 17px 0px 17px 0px;
  transition: 0.9s;
}
.btn:hover {
  background-color: rgb(8, 103, 136);
  color: rgb(219, 222, 223);
}
#addPost:hover {
  box-shadow: 0 0 20px 0 rgba(8, 103, 136, 0.7);
  transition: 1.5s;
}
@media only screen and (max-width: 768px) {
  #addPost {
    width: 96%;
    height: 500px;
    transform: translateY(50px);
    border-radius: 10px;
  }
}
/* .chat {
  position: fixed;
  transform: translateX(400px)
}
.muive {
  display: flex;
} */
</style>