<template>
  <Headingbar />
  <Intro />
  <Body />
  <Base />
</template>

<script>
import Headingbar from '../components/Headingbar.vue'
import Intro from '../components/Intro.vue'
import Body from '../components/Body.vue'
import Base from '../components/Base.vue'
export default {
name: "Ho_me",
components: {
  Headingbar,
  Intro,
  Body,
  Base
}
}
</script>

<style scoped>

</style>