<template lang="en-us">    
<div class="news">
    <p style="margin-right: 10px; padding: 0px; font-size: 17px;text-align:center">
      <span style="font-weight:bold">{{content}}<br></span>
      {{school}}
    </p>
    <div
      style="
        background-color: rgb(34, 116, 143);
        color: white;
        padding: 0px 5px;
        margin-left: 10px;
        border-radius:5px;
        padding:0px 10px;
      "
    >
      <router-link
        to="/reg-tsch"
        style="text-decoration: none; color: white; font-size: 17px;text-align:center"
        target="_blank"
        >Register Now</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
    name: "HeaderNews",
    data(){
        return{
            content:"Registration for TechOrigin Technology Development School July 2024 Batch is ongoing",
            school:"4 months intensive training and internship opportunity"
        }
    }

}
</script>
<style>
.news {
    background-color: rgb(225, 239, 246);
    color: rgb(34, 116, 143);
    padding: 3px;
    font-family: "Segoe UI";
    font-weight: 500;
    font-size: 14px;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    display: flex;
}


@media only screen and (max-width: 800px) {
  
.news {
    background-color: rgb(225, 239, 246);
    color: rgb(34, 116, 143);
    padding: 10px;
    font-family: "Segoe UI";
    font-weight: 500;
    font-size: 14px;
    min-height: 50px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    display: flex;
}
}
</style>