<template>
  <head>
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap"
      rel="stylesheet"
    />
    <link
      href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo"
      rel="stylesheet"
      type="text/css"
    />
  </head>
  <Headingbar />
  <!-- data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true" -->
  <div class="container">
    <div
      class="my-2"
      style="background-color: rgb(34, 116, 143);
        border-radius: 6px;
        padding: 10px 0px;
      "
    >
      <center>
        <h3 class="fw-bold text-light py-2">{{ registration_title }}</h3>
      </center>
    </div>
    <div class="bg-light first">
      <p class="display-6 ps-3 py-3" style="font-size: 15px; font-weight: bold">
        Technology School
      </p>
      <p class="h6 ps-3 pb-2">
        TechOrigin Technology School is a School of Entrepreneurial studies with
        focus in developing technical skills for our participants and giving
        them access to relevant opportunities as well as mentorship to guide
        them in running a successful business enterprise in the technology
        industry.
      </p>
      <p class="h6 ps-3 pb-2" v-if="registration_type == 'tsch'">
        Course Option: <b class="p-text" style="color:rgb(34, 116, 143)">{{courseoption}}</b>
      </p>
      <p class="h6 ps-3 pb-2" v-if="registration_type == 'tsch'">
        Course Schedules: February 11th - April 10th, 2023 (3 Months Intensive
        Training)
      </p>
      <p class="h6 ps-3 pb-2" v-if="registration_type == 'tsch'">
        Course Centre: **Will be communicated via mail with passcode for those
        who have paid
      </p>
      <p class="h6 ps-3 pb-2" v-if="registration_type == 'stsch'">
        Course Schedules: October 2nd 2023 (3 weeks Training)
      </p>
      <hr />
      <p class="h6 ps-3 pb-2">
        Contact us:08136256619 ,08148646311 or info@techorigin.alisutechnology.com
      </p>
    </div>
    <form id="addPost" @submit="onSubmit">
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Name <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your full name"
          type="text"
          id="name"
          v-model="name"
          name="name"
          placeholder="Your Name..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Email <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your Email"
          type="email"
          v-model="email"
          id="email"
          name="email"
          placeholder="Your Mail..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Phone Number <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your Phone number"
          type="number"
          v-model="number"
          id="number"
          name="number"
          placeholder="Your Phone number..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name" v-if="registration_type == 'ptsch'">
        <label class="h6 ps-3 py-2"
          >Which Course do you want to learn?<span style="color: red"
            >*</span
          ></label
        ><br />
        <input
          class="inputs ms-3"
          title="Enter preferred course or list of courses"
          type="text"
          placeholder="Your Answer..."
          v-model="courseoption"
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <p v-if="registration_type == 'ptsch'">
        You can enter multiple course, TechOrigin personnel will call to guide
        you on the best possible path you should take
      </p>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Gender <span style="color: red">*</span></label
        ><br />
        <div class="radio radio-danger ms-3 my-3">
          <input
            type="radio"
            name="survey1"
            id="Radios1"
            v-model="gender"
            value="Male"
            class="me-2"
          />
          <label>Male</label>
        </div>
        <div class="radio radio-danger ms-3 my-3">
          <input
            type="radio"
            name="survey1"
            id="Radios1"
            v-model="gender"
            value="Female"
            class="me-2"
          />
          <label>Female</label>
        </div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Organisation <span style="color: red">*</span></label
        ><br />
        <div class="form-check ms-2">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="organisation"
            value="Entrepreneur"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Entrepreneur</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="organisation"
            value="Student"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Student</label
          >
        </div>
        <div class="form-check ms-2">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="organisation"
            value="Professional Career"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Professional Career</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="organisation"
            value="Others"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Others</label
          >
        </div>
      </div>

      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Location (e.g Port Harcourt, Nigeria)<span style="color: red"
            >*</span
          ></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your Location"
          type="text"
          placeholder="Your Answer..."
          v-model="location"
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Class Option<span style="color: red">*</span></label
        ><br />
        <div class="form-check ms-3">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="virtual"
            id="virtual"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Virtual (Conference Call with Realtime Screen Share)</label
          >
        </div>
        <!-- <div class="form-check ms-3 my-3">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="physical"
            id="physical"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Physical (In our learning centre - Port Harcourt)</label
          >
        </div> -->
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Promo Code (If Any)</label
        ><br />
        <input
          class="inputs ms-3"
          title="Promo Code"
          type="text"
          placeholder="Enter Code..."
          v-model="promocode"
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >How did you know about TechOrigin?</label
        ><br />
        <div class="form-check ms-2">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="informed"
            value="Social Media (LinkedIn,Facebook or Twitter)"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Social Media (WhatsApp, LinkedIn,Facebook or Twitter)</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="informed"
            value="Advert"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Advert</label
          >
        </div>
        <div class="form-check ms-2">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="informed"
            value="Hard Copy Flyer"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Hard Copy Flyer</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="informed"
            value="Google Search"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Google Search</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="Radios"
            v-model="informed"
            value="Others"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Others</label
          >
        </div>
      </div>
      <input
        type="submit"
        value="Submit"
        id="submit"
        class="mt-2"
        v-on:click="onSubmit()"
      />
    </form>
    <p>{{ successful }}</p>
    <div>
      <center>
        <div><hr style="width: 100%" /></div>
      </center>
      <center>
        <p class="fw-light rc" id="par">
          Copyright © 2024 TechOrigin Technology Development Limited RC - 2022065
        </p>
      </center>
    </div>
  </div>
</template>

<script>
import store from "../shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "../shared/storeconstants";
import axios from "axios";
import Headingbar from "../components/Headingbar.vue";
export default {
  name: "RegisterShort",
  components: {
    Headingbar,
  },
  data() {
    return {
      name: "",
      email: "",
      number: "",
      successful: "",
      gender: "",
      organisation: "",
      courseoption: "Data Analysis with Microsoft Excel",
      physical: false,
      virtual: true,
      promocode: "",
      Informed: "",
      registration_type: "stsch",
      registration_title: "TechOrigin Short Course Registration",
      showSnackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      timer: 3000,
    };
  },

  methods: {
    async onSubmit(e) {
      e.preventDefault();
      if (!this.name) {
        alert("Please Add a Name");
        return;
      }
      if (!this.number) {
        alert("Please Add Phone Number");
        return;
      }
      const NewInformation = {
        id: Math.floor(Math.random() * 100000),
        name: this.name,
        email: this.email,
        number: this.number,
        location: this.location,
        gender: this.gender,
        organisation: this.organisation,
        physical: this.physical,
        virtual: this.virtual,
        promocode: this.promocode,
        informed: this.informed,
        registrationtype:this.registration_type
      };
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec?secretKey=TSch2022",
          JSON.stringify(NewInformation)
        )
        .then(function (response) {
          alert(response.data);
        });
      store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
      this.$router.push("/");
    },
    getTitle() {
      if (this.registration_type == "stsch") {
        this.registration_title = "TechOrigin Short Course Registration";
      } else if (this.registration_type == "ptsch") {
        this.registration_title = "Private Tutorial Registration";
      } else {
        this.registration_title = "Technology School Registration";
      }
    },
  },
};
</script>

<style scoped>
.first {
  box-shadow: 4px 3px 8px 1px #d4d3d3;
  -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
  border: 1px solid rgb(182, 177, 177);
  margin-top: -22px;
  border-radius: 4px;
}
.inputs {
  border: none;
  outline: 0px solid transparent;
  background-color: rgb(248, 249, 250);
  width: 60%;
}

.effect {
  background-color: rgb(8, 103, 136);
  height: 1px;
  width: 0px;
  transition: width 0.8s;
}
.effect {
  transition-timing-function: linear;
}

.name:hover .effect {
  width: 100%;
}
.cover {
  height: 1px;
  width: 60%;
  background-color: rgb(219, 222, 223);
}
#submit {
  width: 20%;
  height: 54px;
  border-radius: 6px;
  color: #d4d3d3;
  font-weight: 600;
  background-color: rgb(8, 103, 136);
  transition: 0.9s;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 40px;
  border: none;
  font-style: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
#submit:hover {
  color: rgb(8, 103, 136);
  background-color: #d4d3d3;
}
#submit:focus {
  font-size: 20px;
  letter-spacing: 3px;
  width: 23%;
  height: 52px;
  transition: 0.6s;
  color: white;
  background-color: rgb(8, 103, 136);
}
@media only screen and (max-width: 600px) {
  #submit {
    font-size: 15px;
    width: 40%;
  }
}
</style>