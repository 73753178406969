import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap'
import store from './shared/store'
import 'axios'
import 'vue-axios'
import AOS from 'aos'
import 'aos/dist/aos.css'

createApp(App).use(router).use(store).use(AOS).mount('#app');
