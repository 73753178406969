<template>
  <head>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap" rel="stylesheet">
    <link href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo" rel="stylesheet"
      type="text/css" />
  </head>
<Headingbar />
<center>
      <div class="col-md-9 justify-content-center">
        <h1 id="par" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; margin:40px 0px 0px 0px;">
          About Us</h1>
        <div style="justify-content: center; width: 80px;background-color: rgb(34, 116, 143);padding: 2px;margin:15px 0px 30px 0px;"></div>
        <p class="context"
                    data-aos="zoom-in"
                    data-aos-delay="500"
                    data-aos-duration="1500">
          TechOrigin is a Technology Training and Entrepreneurship Institute that empowers individuals and organisations
          with relevant technology skills capable of making them excel in their professional career as well as ignite
          outstanding technological innovations for entrepreneurs.</p>
      </div>
</center>

<!-- our Team -->
<section id="team">
  <center>
<div class="container" id="bgg2">
<p class="h3 text-light py-3" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', Arial; letter-spacing: 2px;">OUR TEAM</p>
<div
style="justify-content: center; width: 80px;background-color: white;padding: 2px;margin:5px 0px 30px 0px;color: white;">
</div>
<P class="contex"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">Our team is made up of qualified people and experts who are committed and dedicated 
  to impacting knowlege and skills to you.
</P>
</div>
</center>
</section>

<!-- courses -->
<section id="courses"
data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
<div class="row" style="margin-bottom:20px;">
  <div class="col-md-8">
 <center><div class="container" id="bgg2">
<p class="h3 tex pt-3 pb-2" style="font-size:30px;font-family:'Trebuchet MS', 'Lucida Sans Unicode', Arial; letter-spacing: 2px;">OUR COURSES</p>
<div class="courseline"></div>
</div></center> 
<p class="px-4 py-2" style="font-family:'Trebuchet MS', 'Lucida Sans Unicode', Arial;">
  We currently offer six major technology based courses with comprehensive outline and well revised course notes to help our students acquire all the relevant knowledge that is needed in their chosen field of study. Our Courses are geared towards inspiring our students to develop innovative software solutions both as entrepreneurs and career professionals</p>
  <router-link class="btn-outline mt-2 mb-4 ms-4"  to="/Courses">GO TO OUR COURSES ...</router-link>
  </div>
  <div class="col-md-4" style="display:flex; margin-top: 20px;">
    <ul style="list-style:none; margin-top:20px; margin-bottom:20px; line-height: 30px;">
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Data Analytics</li>
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Website Development</li>
      <Li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Mobile App Development</Li>
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>UI/UX Developmemt</li>
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Cloud Computing</li>
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Desktop App Developmemt</li>
      <li><img src="../assets/ALISU_two.png" style="width: 20px; height:20px;" alt="tech-origin-logo"/>Networking and Local Intranet Infrastructure</li>
    </ul>
  </div>
</div>
</section>

<!-- Who should Enrol -->
<center><div class="row py-5" style="background-color: rgb(8,103,136); justify-content:center;"
  data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
    <center><p class="h3 fw-bold text-light py-2">WHO SHOULD ENROLL ?</p></center>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
      <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"/>
      </svg>&nbsp; Gradutes in all fields</p>
  </div>
  <div class="col-md-6 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
      <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
      </svg>&nbsp; Entrepreneurs, Business managers, Digital marketers</p>
  </div>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/>
</svg>&nbsp; IT Specialists</p>
  </div>
</div></center>


  <!-- Traing review -->
  <section id="review"
  data-aos="zoom-in"
        data-aos-duration="2500"
        data-aos-delay="500"
        data-aos-once="true">
    <div class="col-12" style="background-color: whitesmoke;padding: 30px 0px 0px 0px;">
    <div style="display:flex;justify-content: center;flex-direction: column;">
    <h1 id="par"
      style="font-size:30px;
      font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
      margin:0px 0px 0px 0px;text-align: center;">
      What People Say about our programs</h1>
      <center>
        <div style="background-color:rgb(8,103,136);width:80px;padding: 2px;margin: 15px 0px 30px 0px;">
        </div>
      </center>
    </div>
    
      <div id="carouselExampleInterval" class="carousel slide col-12" 
      data-bs-ride="carousel" style="background-color: transparent;">
        <center>
          <div class="carousel-inner">
            <div class="carousel-item active" data-bs-interval="8000">
              <section style="background-color: rgb(8,103,136); width: 460px; border-radius: 5px; height:320px"
                id="pacc">
                <img src="../assets/person-circle.svg" style="width:60px; height:60px;" id="users"/>
                <p class="d-block w-100 pt-2 h6 fw-bold" alt="..." id="orev">
                  By Philip Akpan
                </p>
                <p class="h6" id="orevv" style="line-height: 25px;width: 80%;">
                  "I registered for techorigin 1.0 with a high expectation to learn data analysis. Not only that my expectation was met, but my boldness and confidence in working with data got boosted. You need value for your money? Techorigin is the real deal."</p>
                <span class="h6 fw-bold pb-1" id="orev">[Tsch 1.0 student]</span>
              </section>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <section style="background-color: rgb(8,103,136); width:450px; border-radius: 5px; height:350px;padding: 5px 0px 5px 0px;"
                id="pacc">
                <img src="../assets/person-circle.svg" style="width:60px; height:60px;" id="users" />
                <p class="d-block w-100 h6 fw-bold pt-3" id="orev">
                  By Maryam AbdulKareem</p>
                <p class="h6" id="orevv" style="line-height: 25px;width: 80%;">
                  "I started my tech journey officially in February at TechOrigin. I will like to say a big Thank you to all the facilitators, I was carefully guided and shown the way things should and shouldn't be done. And one amazing thing I must also commend the academy for is the way they celebrate your baby steps. I'm forever grateful for everything. Thank you"</p>
                <span class="h6 fw-bold pb-1" id="orev">[Tsch 1.0 student]</span>
              </section>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <section style="background-color: rgb(8,103,136); width: 460px; border-radius: 5px; height:350px"
                id="pacc">
                <img src="../assets/person-circle.svg" style="width:60px; height:60px;" id="users" />
                <i class="bi bi-person-circle"></i>
                <p class="d-block w-100 pt-2 h6 fw-bold" alt="..." id="orev">
                  By Samuel Jaja
                </p>
                <p class="h6" id="orevv" style="line-height: 25px;width: 80%;">
                  "Technology School 1.0 was a life transforming event and experience. Staring from the patience of the facilitators to the depth of content and content delivery truly I will say I learnt a lot and it was worth giving my time. So far in the year 2022 one of my best decisions is being a participant of technology School 1.0."</p>
                <span class="h6 fw-bold pb-1" id="orev">[Tsch 1.0 student]</span>
              </section>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <section style="background-color: rgb(8,103,136); width: 460px; border-radius: 5px; height:320px"
                id="pacc">
                <img src="../assets/person-circle.svg" style="width:60px; height:60px;" id="users" />
                <i class="bi bi-person-circle"></i>
                <p class="d-block w-100 pt-2 h6 fw-bold" alt="..." id="orev">
                  By Chimaobim Aruah
                </p>
                <p class="h6" id="orevv" style="line-height: 25px;width: 80%;">
                 "I was exposed to lots of Technology advanced skills. Starting from Data analysis to Front-end and Back-end web development. Before I registered with TechOrigin I had no idea on how to create or design a website but now I can on my own build a fully functional website. All thanks to the team in Charge of Tech Origin."</p>
                <span class="h6 fw-bold pb-1" id="orev">[Tsch 1.0 student]</span>
              </section>
            </div>
            <div class="carousel-item" data-bs-interval="8000">
              <section style="background-color: rgb(8,103,136); width: 460px; border-radius: 5px; height:320px"
                id="pacc">
                <img src="../assets/person-circle.svg" style="width:60px; height:60px;" id="users" />
                <i class="bi bi-person-circle"></i>
                <p class="d-block w-100 pt-2 h6 fw-bold" alt="..." id="orev">
                  By Emmanuel Asibor
                </p>
                <p class="h6" id="orevv" style="line-height: 25px;width: 80%;">
                  "The tutors where awesome ,always ready, patient and willing to explain and make sure everyone comprehends what is being thought. i am a proud data analyst and full stack developer because of them...thanks TSch 1.0,I am truly grateful for the knowledge impacted on me"</p>
                <span class="h6 fw-bold pb-1" id="orev">[Tsch 1.0 student]</span>
              </section>
            </div>
          </div>
        </center>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleInterval"
          data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleInterval"
          data-bs-slide="next">
          <span class="carousel-control-next-icon"
           aria-hidden="true"
           style="fill: black;"></span>
        </button>
      </div>
</div>
</section>
<!-- base -->
<Base />
</template>

<script>
import Headingbar from '../components/Headingbar.vue'
import Base from '../components/Base.vue'
export default {
name: "Abo_ut",
components: {
  Headingbar,
  Base,
}
}
</script>

<style scoped>
#par {
  color: rgb(8, 103, 136);
  font-family: Nunito, sans-serif;
  line-height: 27.2px;
}
/* .ideas{
  background-color: rgb(8,103,136); 
} */
.brief{
  margin-left: 4%;
  margin-right: 4%;
  margin-top: 8px;
  border-radius: 4px;
  background-color: rgb(225, 239, 246);
  overflow: hidden;
  height: 350px;
  margin-bottom: 20px;
  transition: 0.7s;
  justify-self: center;
  position: relative;
  color: rgb(228, 221, 221);
}
@media only screen and (max-width: 992px) {
.brief{
  margin-left:0%;
  margin-right: 0%;
}
#brieff{
  margin-left:0%;
  margin-right: 0%;
}
}
@media only screen and (max-width: 600px) {
  .brief{
    width: 46%;
    margin: 0% 1% 1% 1%;
  }
  #brieff{
  width: 80%;
  margin: 0% 9% 0% 9%;
  height: 280px;
}
}
#par2{
  height: 350px;
  padding-top: 30%;
  opacity: 0.3;
}
.brief:hover {
  cursor: pointer;
}
.brief:hover #par1{
transform: translateY(-100%);
transition: 1.8s;
transition-timing-function: ease;
opacity: 0.2;
}
.brief:hover #par2{
transform: translateY(-100%);
transition: 2.8s;
transition-timing-function: ease;
opacity: 1;
}
.tex{
  color: rgb(8,103,136);
}

#ideas{
  /* background-color: rgb(8,103,136); */
  position: relative;
  overflow: hidden;
}
#team{
  background-color: rgb(8,103,136);
}
.bgg2{
  margin-top: 20px;
  margin-bottom: 20px;
}
.context {
 font-family:'Nunito' sans-serif;
 line-height: 1.9;
 font-size: 18px;
 padding-bottom: 40px;
}
.contex{
  color:white;
  font-family:'Nunito' sans-serif;
  line-height: 1.9;
  font-size: 18px;
  padding-bottom: 40px;
}
.courseline{
  margin-top: 0px;
  margin-bottom: 20px;
  background-color: rgb(8,103,136);
  width: 25%;
  height: 3px;
  justify-content: center;
  border-radius: 5px;
}
.btn-outline{
  color: black;
  transition: 1s all ease;
  letter-spacing: 2px;
  text-decoration: none;
  font-size: 17px;
}
.btn-outline:hover{
  color: rgb(8,103,136);
  z-index: -1;
  transition: all 1s ease;
}
#carouselExampleInterval {
  height: 340px;
  background-color: rgb(8, 103, 136);
  border-radius: 3px;
}

#carouselExampleInterval:hover {
  cursor: pointer;
}

#orev {
  color: #F3F7FA;
  font-family: Nunito, sans-serif;
}

#orevv {
  color: #F3F7FA;
  margin-top: 10px;
}
#users {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  margin-top: 10px;
  color: white;
}
@media only screen and (max-width: 600px) {
  #pacc {
  transform: translateX(-37px);
  height:600px
}
}
</style>