<template>
  <Loader v-if="showLoading"></Loader>
  <router-view />
</template>

<script>
import axios from "axios";
import Loader from "../src/components/loader.vue";
import AOS from "aos";
import { mapState } from "vuex";
import store from "./shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "./shared/storeconstants";
export default {
  name: "App",
  data() {
    return {
      sent_data: false,
    };
  },
  computed: {
    ...mapState({
      showLoading: (state) => state.showLoading,
    }),
  },
  methods: {
    async sendUserData() {
      if(!this.sent_data){
        try {
        await axios
          .get(
            "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec"
          );
        } catch (error) {
          console.log(error)
        }
      }
      this.sent_data=true;
    },
  },
  components: {
    Loader,
  },
  async mounted() {
    AOS.init({ once: true });
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
    await new Promise((r) => setTimeout(r, 3000));
    store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    this.sendUserData();
  },
};
</script>

<style>
</style>
