<template lang="en-us">  
    <head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    </head>
    <div class="school-div">
        <div class="schools">
            <div class="school-box">
                <p class="header">Coding &nbsp;<span><i class="fa fa-code"></i></span></p>
                <p class="school-title">Software Development and Product Engineering</p>
                <p class="school-content">Enroll in our live virtual classes to learn how to build advanced software for mobile, web, and desktop users using the latest technology frameworks and industry-standard practice. Let's be part of your development journey"</p>
                <a href="#program" style='color:rgb(8, 103, 136)' class="self-end" >View Programs &nbsp;&nbsp;<i class="self-end-arrow fa fa-arrow-right"></i></a>
            </div>
            <div class=school-box>
                <p class="header">Data Science &nbsp; <span><i class="fa fa-database"></i></span></p>
                <p class="school-title">Data Science and Business Intelligence</p>
                <p class="school-content">Improve your business decision-making and analytical skills by enrolling in our virtual school, where you'll explore the areas of data analysis and business intelligence through hands-on projects and interactive classes.</p>
                <a style='color:rgb(8, 103, 136)' href="#program" class="self-end">View Programs &nbsp;&nbsp;<i class="self-end-arrow fa fa-arrow-right"></i></a>
            </div>
            <div class="school-box">
                <p class="header">Design & Graphics &nbsp; <span><i class="fa fa-object-group"></i></span></p>
                <p class="school-title">Product Design and Product Management (PDM)</p>
                <p class="school-content">Create user-friendly and visually appealing products through thoughtful design processes, and manage effectively the entire lifecycle of your project by joining our PDM school with access to live project and hands-on practices</p>
                <a style='color:rgb(8, 103, 136)' href="#program" class="self-end">View Programs &nbsp;&nbsp;<i class="self-end-arrow fa fa-arrow-right"></i></a>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "SchoolS"
}
</script>
<style>
.school-div {
    padding: 10px;
    margin:30px 0px;
}

.schools {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:10px;
    justify-content: space-between;
    align-items: stretch;
    padding: 10px;
}

.header {
    background-color: #F3F7FA;
    border-radius: 10px;
    width: fit-content;
    color: rgb(8, 103, 136);
    padding: 5px 10px;
    font-weight: bold;
    font-size: 14px;
}

.school-box {
    border-radius: 10px;
    border: #F3F7FA 2px solid;
    box-shadow: #d6e8f6 5px 5px 10px;
    padding: 20px;
    display: flex;
    min-height: 310px;
    flex-direction: column;
}
.school-box:hover{
    border: rgb(8, 103, 136) 1px solid;
}
.school-title {
    font-size: 20px;
    font-weight: bold;
}
.school-content{
    margin-bottom:30px;
    line-height: 2;
}
.self-end{
    align-self: flex-start;
    background-color: white;
    color: rgb(8, 103, 136);
    border: none;
}
.self-end:hover{
    cursor: pointer;
}
.self-end-arrow{
    animation:infinite;
    animation-name: arrow-translate;
    animation-duration: 1s;
    animation-timing-function: linear;
}
@keyframes arrow-translate {
    to {
        transform: translateX(4px);

    }
}
@media screen and (max-width: 600px) {
.schools {
    display: flex;
    flex-flow: wrap row;
    gap:10px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

}
</style>