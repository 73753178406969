<template lang="en-us">
    <head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    </head>
    <div>
        <div class="slider-body" v-if="false">
                    <div class="programs-body">
                        <div class="prog-main">
                            <img class="logo-image" src="../assets/techorigin-home.jpg"/>
                            <h3>Fullstack Software Development with MERN Stack</h3>
                            <p>Learn comprehensive website development with MongoDB, ExpressJS, ReactJS, and NodeJS (MERN) to construct a website application suitable for various business entities or any other use</p>
                            <p class="header">Software Development &nbsp;<span><i class="fa fa-code"></i></span></p>
                            <!-- <br> -->
                            <div style="width:100%;margin-bottom:20px;">
                                <router-link class="register-now" to="reg-tsch" style="margin:0px 0px 0px 0px">Get Started</router-link>
                                <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TechOrigin%20Fullstack%20React%20%2B%20ExpressJS.pdf?alt=media&token=1c796c7e-fa3f-41de-9033-7c6c2dcfae0c"
                                target="_blank" class="download-brochure">Download Brochure</a>
                            </div>
                        </div>
                        <div class="col-flex">
                            <img class="prog-img" src="../assets/tsch_2024_2.png"/>
                            <div class="prog-box">
                                <div>
                                    <p class="prog-box-title">Fee</p>
                                    <p class="prog-box-content">150,000 NGN</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Duration</p>
                                    <p class="prog-box-content">3 months</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Internship</p>
                                    <p class="prog-box-content">4 weeks</p>
                                </div>
                            </div>
                        </div>
                    </div>
            <div style="display:none" class="slider-selector">
                    <div class="slider-selector-selected"></div>
                    <div class="slider-selector-unselected"></div>
                    <div class="slider-selector-unselected"></div>
            </div>
        </div>
        <div class="slider-body">

          <h3 class="primary-color" style="
                font-size: 30px;
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                  'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                margin: 40px 0px 10px 0px;
              ">
            Ongoing TTDS Programs
          </h3>
          <div style="
            justify-content: center;
            width: 80px;
            background-color: rgb(34, 116, 143);
            padding: 2px;
            margin: 0px 0px 30px 0px;
          "></div>
                    <div class="programs-body">
                        <div class="prog-main">
                            <img class="logo-image" src="../assets/techorigin-home.jpg"/>
                            <h3>Fullstack Mobile Application</h3>
                            <p>Learn comprehensive mobile application development with <b class="primary">Dart, Flutter, Firebase and MongoDB</b> to build Mobile Application for both iOS and Android.</p>
                            <p class="header">Mobile Application &nbsp;<span><i class="fa fa-mobile" style="font-size:20px"></i></span></p>
                            <!-- <br> -->
                            <div style="width:100%;margin-bottom:20px;">
                                <router-link class="register-now" to="reg-tsch" style="margin:0px 0px 0px 0px">Get Started</router-link>
                                <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TechOrigin%20Flutter%2BFirebase%20Course%20Handout%202024.pdf?alt=media&token=4f3b1eb9-2bc9-4c60-bb08-b18b4e725a5d"
                                target="_blank" class="download-brochure">Download Brochure</a>
                            </div>
                        </div>
                        <div class="col-flex">
                            <img class="prog-img" src="../assets/ttds_fwd_2024.jpg"/>
                            <div class="prog-box">
                                <div>
                                    <p class="prog-box-title">Fee</p>
                                    <p class="prog-box-content">150,000 NGN</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Duration</p>
                                    <p class="prog-box-content">3 months</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Internship</p>
                                    <p class="prog-box-content">4 weeks</p>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
        <div class="divider"></div>
        <div class="slider-body">
                    <div class="programs-body">
                        <div class="prog-main">
                            <img class="logo-image" src="../assets/techorigin-da.jpg"/>
                            <h3>Complete Data Analysis Course</h3>
                            <p>TechOrigin Technology School offers a comprehensive Data Analysis Course that includes extensive training in Microsoft Excel, Power BI, SQL, and Python. This course is designed to equip students with the skills necessary to master these essential tools for data analysis.</p>
                            <p class="header">Data Science & Business Intelligence &nbsp;<span><i class="fa fa-database"></i></span></p>
                            <!-- <br> -->
                            <div style="width:100%;margin-bottom:20px;">
                                <router-link class="register-now" to="reg-tsch" style="margin:0px 0px 0px 0px">Get Started</router-link>
                                <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TTDS%20DA%202024.pdf?alt=media&token=9a94e06a-acab-4a9a-96f0-22e55cae1e9a"
                                target="_blank" class="download-brochure">Download Brochure</a>
                            </div>
                        </div>
                        <div class="col-flex">
                            <img class="prog-img" src="../assets/ttds_da_2024.jpg"/>
                            <div class="prog-box">
                                <div>
                                    <p class="prog-box-title">Fee</p>
                                    <p class="prog-box-content">150,000 NGN</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Duration</p>
                                    <p class="prog-box-content">3 months</p>
                                </div>
                                <div class="prog-border"></div>
                                <div>
                                    <p class="prog-box-title">Internship</p>
                                    <p class="prog-box-content">4 weeks</p>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "CurrentPrograms",
    data() {
        return {
            programs: {},
        };
    },
};
</script>
<style>
.primary{
    color: rgb(8, 103, 136);
}
.download-brochure {
    color: rgb(8, 103, 136);
    background-color: white;
    border: rgb(8, 103, 136) 1px solid;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;

}

.download-brochure:hover {
    text-decoration: underline;
    color: rgb(8, 103, 136);
}

.prog-main {
    padding: 0px 0px;
    line-height: 2;
}

.logo-image {
    width: 150px;
    margin-bottom: 10px;
}

.register-now {
    background-color: rgb(8, 103, 136);
    color: white;
    border: rgb(8, 103, 136) 1px solid;
    border-radius: 5px;
    padding: 10px 20px;
    text-decoration: none;
    cursor: pointer;
}

.register-now:hover {
    text-decoration: none;
    color: white;
}

.programs-body {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1.2fr;
}

.slider-body {
    background-color: #f4f7f9;
    padding: 20px 20px;
}

.prog-img {
    border-radius: 5px;
    width: 400px;
}

.prog-box {
    background-color: rgb(8, 103, 136);
    color: white;
    padding: 10px;
    width: 400px;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    border: 1px goldenrod solid;
    box-shadow: goldenrod 1px 1px 5px;
}

.col-flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin: 10px;
}

.prog-box-content {
    font-weight: bold;
}

.prog-box-title {
    font-size: 12px;
    margin: 0px;

}

.prog-border {
    background-color: whitesmoke;
    width: 2px;
    height: 90%;
}

.header {
    background-color: rgb(229, 241, 244);
    margin-bottom: 15px;
}

.slider-selector {
    display: flex;
    justify-content: center;
    padding: 5px 0px;
    column-gap: 5px;
}

.slider-selector-selected {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: rgb(8, 103, 136);
    border: 1px rgb(8, 103, 136) solid;
    cursor: pointer;
}

.slider-selector-unselected {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: transparent;
    border: 1px rgb(8, 103, 136) solid;
    cursor: pointer;
}

.divider {
    height: 2px;
    width: 100%;
    background-color: #bdd6dd
}

@media screen and (max-width: 600px) {
    .programs-body {
        display: block;
        background-color: #f4f7f9;
    }

    .prog-main {
        padding: 0px 0px;
        line-height: 2;
    }

    .prog-img {
        border-radius: 5px;
        width: 370px;
    }

    .prog-box {
        width: 370px;
    }
}
</style>
