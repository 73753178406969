<template>
<Headingbar />
<div class="col-12" style="background-color: white;padding: 50px 0px 0px 0px;">
    <div style="display:flex;justify-content: center;flex-direction: column;">
      <h1 id="par"
        style="font-size:30px;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif; 
        margin:0px 0px 0px 0px;text-align: center; color: rgb(8, 103, 136);">
        Our Blog</h1>
        <center>
          <div style="background-color:rgb(8,103,136);width:80px;padding: 2px;margin: 15px 0px 30px 0px;"></div>
        </center>
    </div>
    </div>
</template>

<script>
import Headingbar from '../components/Headingbar.vue';
export default {
    name: "Bl_og",
    components: {
        Headingbar,
    }
}
</script>

<styles scoped>
#par {
  color: rgb(8, 103, 136);
}
</styles>