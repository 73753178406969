<template>
<section id="begin">
<div class="row" id="cont">
  <center><div class="col-sm-11"><hr style="height:1px;"></div></center>
  <div class="col-md-7">
    <img src="../assets/techoriginbackdrop.png" style="height:340px; width:100%; border-radius:5px;"/>
  </div>
   <div class="col-md-4 pt-3 ps-3">
      <p class="h5 fw-bold ps-3" id="tex">COURSES...</p>
      <ul class="lised">
        <li><p class="h6 fw-bold text-dark">Frontend and Backend web design</p></li>
        <li><p class="h6 fw-bold text-dark">Website Development with Website Builders (WordPress etc.)</p></li>
        <li><p class="h6 fw-bold text-dark">Data Analytics from scratch</p></li>
        <li><p class="h6 fw-bold text-dark">Graphics Design and UI/UX Development</p></li>
        <li><p class="h6 fw-bold text-dark">Cloud Computing and Cloud Infrastructure Management</p></li>
        <li><p class="h6 fw-bold text-dark">Networking and Local Intranet Infrastructure</p></li>
        <li><p class="h6 fw-bold text-dark">Mobile and Desktop App Development</p></li>
        <li><p class="h6 fw-bold text-dark">** Master classes in Business Leadership and Entrepreneurship Studies</p></li>
      </ul>
    </div>
    <center><div class="col-sm-11"><hr style="height:1px;"></div></center>
</div>
</section>

<!-- Courses -->
<section>
  <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/techorigin-da.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Data Analytics</p>
  <p class="h6 text-dark" id="brief">Data Analysis involves the concept of understanding data
    or information with desire to clean, restructure, transform and extract useful information from
     raw data. Businesses globally thrive on how well they are able to understand the dynamics of their
      business data and using it to drive insightful decisions about their business. &nbsp;<span><router-link to="/DataAnalytics">Read-More...</router-link></span></p></div>
  </div>
    <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/Website_Development.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Website Development</p>
  <p class="h6 text-dark" id="brief">Website development is the building and maintenance of websites;
    it's the work that happens behind the scenes to make a website look great,
    work fast and perform well with a seamless user experience. Websites are developed
    using variety of coding/programming languages and it can be broken down into front-end and backend web development. &nbsp;<span><router-link to="/Webdesign">Read-More...</router-link></span></p>
  </div>
  </div>
      <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/techorigin-uiux.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">UI/UX development</p>
  <p class="h6 text-dark" id="brief">Graphic design is the creation of visual composition
     to solve problems and communicate ideas or information through words, images, colour and form.
     Graphics Design is usually used to convey information to an audience.
     Whereas UX (user experience) and UI (user interface) are two co-dependent terminologies. UI generally involves the interaction
     between the users and computer systems, software and applications. &nbsp;<span><router-link to="/UI_UX">Read-More...</router-link></span></p></div>
  </div>
  <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/Desktop-App-Development.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Desktop Application Development</p>
  <p class="h6 text-dark" id="brief">Desktop App Development involves developing software application that compatible
    with Laptops or Desktops. These kind of applications are very necessary if what
    you are building is computational intensive and you want a more optimised speed
    in delivering results and computations.  &nbsp;<span><router-link to="/Desktop">Read-More...</router-link></span></p></div>
  </div>
  <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/Cloud-Computing.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Cloud Computing</p>
  <p class="h6 text-dark" id="brief">Cloud Computing is a network of remote servers hosted on the internet for storing data, retrieving data, running applications and managing them through configuration portals. The cloud provides a number of IT services such as servers, databases, software, virtual storage, and networking, among others. &nbsp;<span><router-link to="/Cloud">Read-More...</router-link></span></p></div>
  </div>
    <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/techorigin-mobile.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Mobile Application Development</p>
  <p class="h6 text-dark" id="brief">Its a computer program or software application designed to 
run on a mobile device such as a phone, tablet, or watch. Mobile app development is currently developed for majorly
two Operating System (iOS and Android). Mobile Application can be developed using Native Application programming
language like Java, Swift or Objective-C or Cross Platform programming language like Flutter, Xamarin or Ionic. &nbsp;<span><router-link to="/Appdevop">Read-More...</router-link></span></p></div>
  </div>
      <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/website-development-1024x724.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Website Developmemt Using Web-Builders</p>
  <p class="h6 text-dark" id="brief">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  Temporibus, a quos, iste beatae tempora obcaecati, commodi
  rem aut voluptatem tenetur ratione? Animi ipsam aspernatur
  cupiditate molestiae alias hic et! Enim, assumenda et saepe
  veritatis nostrum asperiores sapiente reprehenderit ab ipsam,
  dolore optio nisi alias facilis provident? Ipsa saepe iste
  molestiae incidunt laudantium! Esse ipsum repellat,
  perferendis recusandae ipsa obcaecati natus! &nbsp;<span><router-link to="/Appdevop">Read-More...</router-link></span></p></div>
  </div>
      <div class="row" id="cuz">
<div class="col-md-3">
<img src="../assets/techorigin-mobile.jpg" class="sameimg"/></div>
<div class="col-md-9">
  <p class="h3 fw-bold pb-3 pt-2" id="tex">Networking and local Intranet Infrastructure</p>
  <p class="h6 text-dark" id="brief">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
  Temporibus, a quos, iste beatae tempora obcaecati, commodi
  rem aut voluptatem tenetur ratione? Animi ipsam aspernatur
  cupiditate molestiae alias hic et! Enim, assumenda et saepe
  veritatis nostrum asperiores sapiente reprehenderit ab ipsam,
  dolore optio nisi alias facilis provident? Ipsa saepe iste
  molestiae incidunt laudantium! Esse ipsum repellat,
  perferendis recusandae ipsa obcaecati natus! &nbsp;<span><router-link to="/Appdevop">Read-More...</router-link></span></p></div>
  </div>
</section>

</template>

<script>
export default {
name:"Pro_grams"
}
</script>

<style scoped>
#cuz{
  justify-content: center;
  margin: 3%;
  padding: 10px;
  border-radius: 6px;
}

#tex {
  color: rgb(8,103,136);
}
#cont {
  justify-content: center;
  background-color: rgb(248, 249, 250);
}
#rep {
  transform:translateX(30px);
}
#eff:hover {
  background-color: rgb(121, 189, 212);
}
.lised {
  font-family: Nunito, sans-serif;
}
.sameimg{
  width: 100%;
  height: 200px;
}
#cuz {
  border-radius: 6px;
  overflow: hidden;
  background-color: white;
  box-shadow: 4px 3px 8px 1px #d4d3d3;
  -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
  transition: 0.7s;
}
#cuz:hover {
  box-shadow: 4px 3px 8px 1px #969696;
  -webkit-box-shadow: 4px 3px 8px 1px #969696;
  cursor: pointer;
}
#brief{
  line-height: 28px;
}
#brief{
  font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}
</style>