<template lang="en-us">
    <head>
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
    </head>
    <div class="background">
        <div class="review-tab">
            <div style="display:flex;flex-direction:column;justify-content:center;align-items:center">
                <img  style="width:70px;"  src='../assets/ALISU_logo.png'/>
                <p style="margin:0px 0px 5px 0px;text-align:center;font-weight:bold;font-size:18px;color:rgb(8, 103, 136)">TechOrigin Technology School Reviews</p>
                <p style="margin:0px;text-align:center" v-if="!success">Your feedback helps us to be better and improve our programs</p>
                <p style="margin:0px;text-align:center" v-if="success">Thank you. Your feedback is highly appreciated. Cheers!"</p>
            </div>

    <form id="addPost" @submit="onSubmit" v-if="!success">
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Fullname <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your full name"
          type="text"
          id="name"
          v-model="fullname"
          name="name"
          placeholder="Your Name..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>

      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Review Rating <span style="color: red">*</span></label
        >
        <br />
        <div>
            <div style="position: relative;display: flex;margin:0px 0px 0px 20px">
                <div style="position: absolute">
                    <p><span class="review-rating" v-for="i in 5" :key="i"><i class="fa fa-star-o" @click="setRating(i)"></i></span></p>
                </div>
                <p v-if="rating<=0"><span class="review-rating" v-for="i in 5" :key="i"><i class="fa fa-star-o"></i></span></p>
                <p><span class="review-rating" v-for="i in rating" :key="i"><i class="fa fa-star" @click="setRating(i)"></i></span></p>
            </div>
            </div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Comment <span style="color: red">*</span></label
        ><br />
        <textarea
          class="inputs ms-3 text-area-input"
          title="Review"
          type="text"
          v-model="comment"
          placeholder="Comment..."
          required
        ></textarea>
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <input
        type="submit"
        value="Submit"
        id="submit"
        class="submit-button"
        v-on:click="onSubmit()"
      />
    </form>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import store from "../shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "../shared/storeconstants";
export default {
    name: "ReviewTab",
    data() {
        return {
            rating: 0,
            fullname: "",
            comment: "",
            success:false
        }
    },
    methods:{
        setRating(i){
            this.rating=i
        },
        async onSubmit(e) {
        e.preventDefault();
        if (!this.fullname) {
            alert("Please add a Name");
            return;
        }
        if (!this.comment) {
            alert("Please add a comment");
            return;
        }
        if (this.rating==0) {
            alert("Please add a review");
            return;
        }
        const NewInformation = {
            date: Date().toString(),
            fullname: this.fullname,
            rating: this.rating,
            comment: this.comment,
        };
        console.log(NewInformation)
        store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
        await axios
            .post(
            "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec?secretKey=ReviewsTsch",
            JSON.stringify(NewInformation)
            )
            .then(function () {
            store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
            });
            this.success=true
     }
    }
}
</script>
<style>
.review-rating {
    color: rgb(8, 103, 136);
    font-size: 30px;
    margin-right: 5px;
    cursor: pointer;
}

.background {
    background-color: rgb(8, 103, 136);
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.review-tab {
    width: 50%;
    background-color: white;
    border: white solid 2px;
    box-shadow: 1px 1px 1px white;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-direction: column;
    padding: 10px;
    row-gap: 10px;
}


.submit-button {
    background-color: rgb(8, 103, 136);
    color: white;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    border: none
}

.first {
    box-shadow: 4px 3px 8px 1px #d4d3d3;
    -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
    border: 1px solid rgb(182, 177, 177);
    margin-top: -22px;
    border-radius: 4px;
}

.inputs {
    border: none;
    outline: 0px solid transparent;
    background-color: rgb(248, 249, 250);
}

.text-area-input {
    min-height: 70px;
    width: 95%;
}

.effect {
    background-color: rgb(8, 103, 136);
    height: 1px;
    width: 0px;
    transition: width 0.8s;
}

.effect {
    transition-timing-function: linear;
}

.name:hover .effect {
    width: 100%;
}

@media screen and (max-width: 600px) {
    .review-tab {
        width: 100%
    }
    .text-area-input{
        width: 90%;
    }
}
</style>