<template>
<Headingbar />
<div class="row" style="background-color: #F4F7F9; justify-content: center;">
<div class="col-md-5">
  <p class="h3 fw-bold pt-5 ps-2" id="par">LEARN MORE ABOUT GRAPHICS DESIGN AND USER EXPERIENCE DEVELOPMENT...</p>
  <p class="h6 fw-bold pt-2 ps-2 text-secondary">Graphic design is the creation of visual composition
     to solve problems and communicate ideas or information through words, images, colour and form.
     Graphics Design is usually used to convey information to an audience.
     Whereas UX (user experience) and UI (user interface) are two co-dependent terminologies. UI generally involves the interaction
     between the users and computer systems, software and applications.
     UI is all about designing interfaces, buttons, icons, colours etc. to contribute to a better overall user experience.
     UX generally involves a user's overall experience with a brand, product or service.
     UX products are designed bearing the user in mind. UI/UX development involves the application
     of application of interactive and visual design on websites and web application for positive
     and organized user experience. 
  </p>
  <router-link type="button" class="btn btn-outline mt-2 ms-2 mb-4"  to="/register">ENROLL NOW</router-link>
</div>
<div class="col-md-6">
  <img class="ms-2 my-1" src="../assets/techorigin-uiux.jpg" alt="UI/UX_development_image" style="height: 380px; width: 100%; border-radius: 6px;"/>
</div>
</div>
<svg viewBox="0 0 1000 100" fill="#F4F7F9">
  <path class="elementor-shape-fill" d="M761.9,44.1L643.1,27.2L333.8,98L0,3.8V0l1000,0v3.9"></path>
</svg>
<!-- course modules -->
<div class="row my-5" style="justify-content:center;">
  <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <img src="../assets/classroom.jpg" id="img"/>
  </div>
  <div class="col-md-5" style=" justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Module One</p></li>
      <li class="listed"><svg width="35" height="35" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Understanding Colors and Brand Identity Graphics Design with Adobe Photoshop User Centric Designs </p></li>
      <li><p class="h6 fw-bold text-dark pt-3">Prerequisite:<span class="h6 text-secondary fw-bold">&nbsp;None</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Fee:<span class="h6 text-secondary fw-bold">&nbsp; &#8358;40,000.00</span></p></li>
      <li><p class="h6 fw-bold text-dark">Class Schedule:<span class="h6 text-secondary fw-bold">&nbsp; 15 Classes (2hrs per class)</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Model:<span class="h6 text-secondary fw-bold">&nbsp; Virtual Private Class</span></p></li>
      <li><p class="h6 fw-bold text-dark">Assessment:<span class="h6 text-secondary fw-bold">&nbsp; Assignments, Projects</span></p></li>
      <li><p class="h6 fw-bold text-dark">Certification:<span class="h6 text-secondary fw-bold">&nbsp; Yes</span></p></li>
    </ul>
  </div>
</div>

<div class="row py-5" style="background-color: #F4F7F9; justify-content:center;">
  <div class="col-md-5" style=" justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Module Two</p></li>
      <li class="listed"><svg width="20" height="20" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">User Research and Product Development</p></li>
      <li class="listed"><svg width="35" height="35" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Mastering User Experience Designs with Adobe XD Application Prototyping with Adobe XD and Figma </p></li>
      <li><p class="h6 fw-bold text-dark pt-3">Prerequisite:<span class="h6 text-secondary fw-bold">&nbsp;None</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Fee:<span class="h6 text-secondary fw-bold">&nbsp; &#8358;55,000.00</span></p></li>
      <li><p class="h6 fw-bold text-dark">Class Schedule:<span class="h6 text-secondary fw-bold">&nbsp; 20 Classes (2hrs per class)</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Model:<span class="h6 text-secondary fw-bold">&nbsp; Virtual Private Class</span></p></li>
      <li><p class="h6 fw-bold text-dark">Certification:<span class="h6 text-secondary fw-bold">&nbsp; Yes</span></p></li>
    </ul>
  </div>
  <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <img src="../assets/Booksevenimg.png" id="img"/>
  </div>
</div>

<div class="row py-5" style="background-color: #F4F7F9; justify-content:center;">
<div class="col-md-5" style="overflow:hidden; justify-content:center;">
   <img src="../assets/Desktop-App-Development.jpg" id="img"/>
  </div>
 <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Benefits</p></li>
      <li class="listed"><svg width="19" height="19" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Certificate after successful course completion</p></li>
      <li class="listed"><svg width="19" height="19" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Python for Data Analysis</p></li>
      <li class="listed"><svg width="19" height="19" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Introduction to Machine Learning with Python</p></li>
      <li class="listed"><svg width="19" height="19" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Introduction to Machine Learning with Python</p></li>
      <li class="listed"><svg width="19" height="19" fill="#136788" class="bi bi-file-richtext" viewBox="0 0 16 16">
  <path d="M7 4.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0zm-.861 1.542 1.33.886 1.854-1.855a.25.25 0 0 1 .289-.047l1.888.974V7.5a.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5V7s1.54-1.274 1.639-1.208zM5 9a.5.5 0 0 0 0 1h6a.5.5 0 0 0 0-1H5zm0 2a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1H5z"/>
  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2zm10-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1z"/>
</svg><p class="ps-3 h6 fw-bold">Mentorship</p></li>
    </ul>
  </div>
</div>

<!-- Who should Enrol -->
<center><div class="row py-5" style="background-color: rgb(8,103,136); justify-content:center;">
    <center><p class="h3 fw-bold text-light py-2">WHO SHOULD ENROLL ?</p></center>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
      <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"/>
      </svg>&nbsp; Gradutes in all fields</p>
  </div>
  <div class="col-md-6 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
      <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
      </svg>&nbsp; Entrepreneurs, Business managers, Digital marketers</p>
  </div>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/>
</svg>&nbsp; IT Specialists</p>
  </div>
</div></center>

<Base />
</template>

<script>
import Headingbar from "../components/Headingbar.vue"
import Base from "../components/Base.vue"
export default {
name: "UI_UX",
components: {
  Headingbar,
  Base,
}
}
</script>

<style scoped>
#par {
  color: rgb(8,103,136);
}
#course{
  list-style-type: none;
}
.btn-outline{
  color: rgb(8,103,136);
  border: 1.8px solid rgb(8,103,136);
  transition: 1s all ease;
}
.btn-outline:hover{
  background: rgb(8,103,136);
  color: white;
  z-index: -1;
  transition: all 1s ease;
}
#img {
  width: 100%;
  height: 300px;
  position: relative;
}
.listed {
  display:flex;
}
.bi-pie-chart-fill{
  transform: translateY(2px);
}
</style>