<template lang="en-us">
    <head>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    </head>
    <div>
    <center>
      <div class="j-relative-layout justify-content-center" style="margin-bottom: 30px">
        <img src="../assets/pctwo.svg" class="svg-design"/>
        <h3 class="primary-color" style="
                font-size: 30px;
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                  'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                margin: 40px 0px 10px 0px;
              ">
        Journey with TTDS!
        </h3>
        <div style="
            justify-content: center;
            width: 80px;
            background-color: rgb(34, 116, 143);
            padding: 2px;
            margin: 0px 0px 30px 0px;
          "></div>
        <div class="j-container">
            <div class="j-content-circle-main">
                <div class="j-content-circle">1</div>
                <div>
                    <p class="j-content-title">Register and get started</p>
                    <div class="j-content-separator-container">
                        <div class="j-content-separator"></div>
                    </div>
                    <p class="j-content-subtitle">Complete the TTDS registration form in just 2 minutes, and within 24 hours, you'll receive an email response along with a call from our Technical Instructor to guide you through your training</p>
                </div>
            </div>
            <div class="j-content-circle-main">
                <div class="j-content-circle">2</div>
                <div>
                    <p class="j-content-title">Onboarding and Course guideline</p>
                    <div class="j-content-separator-container">
                        <div class="j-content-separator"></div>
                    </div>
                    <p class="j-content-subtitle">Explore your chosen course details, discover the potential benefits, and gain insights into the overall course structure. Learn about TTDS and how we positively contribute to enhancing technology-based skills.</p>
                </div>

            </div>
            <div class="j-content-circle-main">
                <div class="j-content-circle">3</div>
                <div>
                    <p class="j-content-title">Virtual Learning Experience</p>
                    <div class="j-content-separator-container">
                        <div class="j-content-separator"></div>
                    </div>
                    <p class="j-content-subtitle">We offer a cutting-edge virtual learning platform designed for an excellent online learning experience. Benefit from lecture recordings and convenient access from any location.</p>
                </div>

            </div>
            <div class="j-content-circle-main">
                <div class="j-content-circle">4</div>
                <div>
                    <p class="j-content-title">Personal Project & Presentation</p>
                    <div class="j-content-separator-container">
                        <div class="j-content-separator"></div>
                    </div>
                    <p class="j-content-subtitle">A Technology School experience isn't finished without successfully executing a project based on your acquired knowledge. TTDS projects serve to solidify your learning, with weekly presentations and mentorship provided throughout the development process.</p>
                </div>

            </div>
            <div class="j-content-circle-main">
                <div class="j-content-circle">5</div>
                <div>
                    <p class="j-content-title">Internship and Project Simulation</p>
                    <div class="j-content-separator-container">
                        <div class="j-content-separator"></div>
                    </div>
                    <p class="j-content-subtitle">Students who successfully complete projects are automatically eligible for internships and the chance to engage in a hands-on simulated live project. Gain access to enterprise-based project management tools and software during this valuable opportunity.</p>
                </div>

            </div>
        </div>
      </div>
    </center>
        
    </div>
</template>
<script>
export default {
    name:"LearningJourney"
}
</script>
<style scoped>
.primary-color {
  color: rgb(8, 103, 136);
}
.j-container{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-flow: row wrap;
    column-gap:30px;
    row-gap: 30px;
}
.j-content-circle{
    height: 60px;
    width: 60px;
    border-radius: 50%;
    margin: 0px 20px;
    background-color: rgb(8, 103, 136);
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 18px;
    color: white;
    border: rgb(8, 103, 136) ;
    box-shadow: rgb(8, 103, 136) 2px 2px 5px;
}
.j-content-arrow{
    width: 70px;
}
.j-content-separator{
    width: 20px;
    height: 3px;
    background-color: rgb(8, 103, 136);
}
.j-content-separator-container{
    display:flex;
    justify-content:flex-start;
    margin: 5px 0px 8px 0px;
}
.j-content-title{
    font-weight: bold;
    color: rgb(8, 103, 136);
    font-size: 17px;
    text-align: left;
    margin:0px

}
.j-content-subtitle{
    max-width:290px ;
    text-align: left;
    line-height: 1.5;
}
.j-content-circle-main{
    display: flex;
}
.j-relative-layout{
    position: relative;
}
.svg-design{
    position:absolute;
    bottom:-30px;
    right:0px;
    width:400px;
    z-index:-100;
}

@media screen and (max-width: 600px) {
.svg-design {
    display: none;
}

}
</style>