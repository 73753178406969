<template>
<head>
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap" rel="stylesheet">
    <link href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo" rel="stylesheet"
      type="text/css" />
  </head>
    <div class="row" style="background-color: #f3f7fa;">
      <div class="col-md-5 pt-4 ps-3" id="parr">
          <div style="display:flex;" class="mt-2">
          <img src="../assets/ALISU_two.png" style="width: 60px; height:60px;" alt="tech-origin-logo" class="ms-2"/>
          <p class="fw-bold h3 ms-1" style="padding-top:13px" id="par">TechOrigin</p>
          </div>
           <p class="ps-3 pe-5" style="line-height: 27px;">
            TechOrigin Technology Institute is deliberate in ensuring our students gain mastery in their chosen course of study within the period of participation for our training. We encourage hands-on practice with regular assignments, projects and presentations to help our students build technical competence and value driven skills capable of making them thrive within any organisation.
           </p>
        </div>
        <div class="col-md-2 pt-4 ps-3" id="parr">
          <p class="fw-bold h4 ps-2 pt-4" id="par">Quick Links</p>
          <ul class="listed  ps-2">
            <li style="padding: 10px 0px 0px 0px;"><a class="h6 fw-light" href="#about" style="color: black; text-decoration: none;">About</a></li>
            <li style="padding: 10px 0px 0px 0px;"><a class="h6 fw-light" href="#schools" style="color: black; text-decoration: none;">Programs</a></li>
            <li style="padding: 10px 0px 0px 0px;"><a class="h6 fw-light" href="#schools" style="color: black; text-decoration: none;">Resources</a></li>
            <li style="padding: 10px 0px 0px 0px;"><router-link class="h6 fw-light" to="/reg-tsch" style="color: black; text-decoration: none;">Register</router-link></li>
            <li style="padding: 10px 0px 10px 0px;"><router-link class="h6 fw-light" to="/Contact" style="color: black; text-decoration: none;">Write A Review</router-link></li>
          </ul>
        </div>
        <div class="col-md-5 pt-4 ps-3" id="parr">
          <p class="fw-bold h4 ps-2 pt-4" id="par">Get In Touch</p>
          <p class="fw-bold h6 ps-2 pt-1" id="par">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-geo-alt-fill" viewBox="0 0 16 16">
            <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"/>
            </svg></span>
                <span class="h6 fw-light text-dark ps-2" id="locate">No. 8 Adele Close, Eliowhani, Rumuodara, Port-Harcourt Nigeria</span></p>
           <div id="baseflex">
            <section id="mail">
           <p class="fw-bold h6 ps-2" id="par">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-envelope-fill" viewBox="0 0 16 16">
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"/>
            </svg>
                </span><a class="h6 fw-light text-dark ps-2" id="locate" href="mailto:info@techorigin.alisutechnology.com">info@techorigin.alisutechnology.com</a></p>
                <p class="fw-bold h6 ps-2" id="par">
            <span><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" class="bi bi-telephone-fill" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z"/>
            </svg>
                </span> <a class="h6 fw-light text-dark ps-2" id="locate" href="tel:08185971473">08185971473</a></p>
            </section>
            <div id="social" class="pt-2">
            <!-- <p class="fw-bold h6 ps-2" id="par">
            Social</p> -->
            <a href="https://web.facebook.com/search/top?q=alisutech%20ng" target="_blank" class="ps-3"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(8,103,136)" class="bi bi-facebook" viewBox="0 0 16 16">
            <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
            </svg></a><br>
            <a href="https://www.linkedin.com/company/techorigin/" target="_blank" class="ps-3">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(8,103,136)" class="bi bi-linkedin" viewBox="0 0 16 16">
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"/>
            </svg></a><br>
            <a href="https://wa.me/+2347046229297" target="_blank" class="ps-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="rgb(8,103,136)" class="bi bi-whatsapp" viewBox="0 0 16 16">
            <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
          </svg>
            </a>
            </div>
             </div>
        </div>
        <center><div class="col-sm-12"><hr></div></center>
        <center><p class="fw rc" id="par">Copyright © 2024 TechOrigin Technology Development Limited RC - 2022065</p></center>
    </div>
</template>

<script>
export default {
name:"Ba_se",
}
</script>

<style scoped>
#par {
  color: rgb(8,103,136);
}
.rc {
  font-size: 13px;
}

#bttn{
  background-color: rgb(8,103,136);
  color: white;
  transition: 0.5s;
  justify-content: end;
}
#bttn:hover {
  background-color: rgb(54, 160, 196);
}
.listed {
  list-style-type: none;
}
#locate {
   line-height: 1.9;
   text-decoration: none;
}
/* #baseflex {
  display: flex;
} */
#mail {
  display: block;
}
#social {
  display: flex;
}
</style>