<template>
  <head>
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css">
    <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
  </head>
  <section id="review" class="mt-5">
    <div class="col-12" style="background-color: #f3f7fa; padding: 50px 0px 0px 0px">
      <div data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500" data-aos-once="true">

        <center>
          <div class="justify-content-center">
            <h3 class="primary-color" style="
                font-size: 30px;
                font-family: 'Trebuchet MS', 'Lucida Sans Unicode',
                  'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
                margin: 20px 0px 10px 0px;">
              WHAT PEOPLE SAY ABOUT TTDS
            </h3>

            <div style="
            justify-content: center;
            width: 80px;
            background-color: rgb(34, 116, 143);
            padding: 2px;
            margin: 0px 0px 30px 0px;
          "></div>
          </div>
        </center>

        <div id="carouselExampleInterval" class="carousel slide col-12" data-bs-ride="carousel"
          style="background-color: transparent">
          <center>
            <div class="carousel-inner">
              <div class="carousel-item active" data-bs-interval="8000">
                <section style="
                    background-color: rgb(8, 103, 136);
                    width: 330px;
                    border-radius: 5px;
                    padding: 10px;
                  " id="pacc">
                  <div>
                  <div class="space-between-flex">
                    <div class="simple-flex">
                      <p><i class="fa fa-user user-icon"></i></p>
                      <p class="title-style" alt="...">
                        Philip Akpan
                      </p>
                    </div>
                    <div>
                      <div style="position: relative;display: flex;width: 80px;">
                        <div style="position: absolute">
                          <p><span class="rating" v-for="i in 5" :key="i"><i class="fa fa-star-o"></i></span></p>

                        </div>
                        <p><span class="rating" v-for="i in 5" :key="i"><i class="fa fa-star"></i></span></p>
                      </div>
                    </div>
                  </div>
                  <p class="h6" id="orevv" style="line-height: 25px">
                    "I registered for techorigin 1.0 with a high expectation to
                    learn data analysis. Not only that my expectation was met,
                    but my boldness and confidence in working with data got
                    boosted. You need value for your money? Techorigin is the
                    real deal."
                  </p>
                  <br>
                  <p class="header">Data Science &nbsp; <span><i class="fa fa-object-group"></i></span></p>

                  </div>
                </section>
              </div>

              <div class="carousel-item" data-bs-interval="8000" v-for="review in reviews" :key="review">

                <section style="
                    background-color: rgb(8, 103, 136);
                    border-radius: 5px;
                    width: 330px;
                    margin: 20px;
                  " id="pacc">
                  <div class="space-between-flex">
                    <div class="simple-flex">
                      <p><i class="fa fa-user user-icon"></i></p>
                      <p class="title-style" alt="...">
                        {{ review.name }}
                      </p>
                    </div>
                    <div>
                      <div style="position: relative;display: flex;width: 80px;">
                        <div style="position: absolute">
                          <p><span class="rating" v-for="i in 5" :key="i"><i class="fa fa-star-o"></i></span></p>

                        </div>
                        <p><span class="rating" v-for="i in review.rating" :key="i"><i class="fa fa-star"></i></span></p>
                      </div>
                    </div>
                  </div>
                  <p class="h6" id="orevv" style="line-height: 25px">
                    {{ review.review }}
                  </p>
                  <p class="header">{{ review.school }} &nbsp; <span><i class="fa fa-object-group"></i></span></p>
                  <br>
                </section>
              </div>
            </div>
          </center>
          <button class="carousel-control-prev" style="color:rgb(8, 103, 136);
            font-size:30px" type="button" data-bs-target="#carouselExampleInterval" data-bs-slide="prev">
            &lt;
          </button>
          <button class="carousel-control-next primary-color" style="color:rgb(8, 103, 136);font-size:30px" type="button"
            data-bs-target="#carouselExampleInterval" data-bs-slide="next">
            >
          </button>
        </div>
      </div>
      <!-- accordion codes -->
      <div class="row mt-4" style="padding-left: 10px" data-aos="zoom-in" data-aos-duration="2500" data-aos-delay="500"
        data-aos-once="true">
        <div class="col-md-3" style="display: flex; justify-content: center">
          <img class="askin" src="../assets/faq.png" width="320px" style="margin: 10px 0px 20px 0px" />
        </div>
        <div class="accordion accordion-flush col-md-9" id="accordionFlushExample" style="width: 70%; min-width: 500px">
          <p class="my-3 fw-bold h4 ps-3 faq" id="par">
            FREQUENTLY QUESTIONS ASKED
          </p>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingOne">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                HOW MUCH FOR OUR PROGRAMS
              </button>
            </h2>
            <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                Our courses are at different fees depending on your courses
                module. You can know the cost for the course of your choice by
                checking our program details.
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingTwo">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                HOW MANY HOURS FOR EACH CLASSES
              </button>
            </h2>
            <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                Classes are scheduled depending on the Technology School details as well as the student preference in a case of private tutorial
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingThree">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                HOW DO I LOCATE THE TRAINING CENTER?
              </button>
            </h2>
            <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                <p>
                  From Rumuodara Junction <span id="parr">>></span> Take a Keke
                  going towards Tank <span id="parr">>></span> Drop at Dilosa
                  Hotel Junction or Jonyson Filling station
                  <span id="parr">>></span> Enter the street and walk to the end
                  of the street and take your only right turn
                  <span id="parr">>></span> Take the first turn on your right
                  opposite a Deeper life Church <span id="parr">>></span> Walk
                  down till the end of the close <span id="parr">>></span> Call
                  0903184475 for further clarification
                </p>
              </div>
            </div>
          </div>
          <div class="accordion-item">
            <h2 class="accordion-header" id="flush-headingFour">
              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseThree">
                WILL I GET A CERTIFICATE AFTER THE PROGRAM
              </button>
            </h2>
            <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour"
              data-bs-parent="#accordionFlushExample">
              <div class="accordion-body">
                Certificates will be issued at the end of the program if all
                projects have been completed and submitted
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "B_ody",
  data() {
    return {
      reviews: [
        {
          name: "Chimobi Godswill Aruah",
          review:
            "Tsch 1.0 was really exciting as I was exposed to lots of Technology advanced skills. Starting from Data analysis to Front-end and Back-end web development. Before I registered with TechOrigin I had no idea on how to create and design a website but now I can on my own build a fully functional website. So I can say I really improved, thanks to the team in Charge of Tech Origin. Thanks alot for all your effort in organizing these programs.",
            school:"Website Dev",
            rating:5
        },

        {
          name: "Precious Chinedum",
          review:
            "I did an Advance Excel training with Techorigin 1.0 and it was mind blowing.  It's really amazing what you can do with your excel tool, especially Data Analysis.  Thank you.",
            school:"Data Analysis",
            rating:4
        },

        {
          name: "Deborah Nwosu",
          review:
            "I was pretty excited when Tech School 1.0 flagged off and I discovered there was a track on Data Analysis. That was just what I needed to learn for my next career advancing step. But with this excitement came some anxiousness. Let’s just say that me and learning technical skills have not had a good relationship. It either get too challenging or boring along the way. I’m very glad I enrolled with TechOrigin. Apart from being highly skilled, the tutors are extremely patient and attentive. The classes are interactive and hands-on. The assignments are well thought out to give just the right degree of challenge needed. I learned virtually all through but it always felt like a physical class. I enjoyed learning at TechOrigin and will recommend them a thousand times over. Definitely now my one stop hub to learn any technical skill..",
            school:"Data Analysis",
            rating:5
        },

        {
          name: "Samuel Jaja",
          review:
            "Technology School 1.0 was a life transforming event and experience. Staring from the patience of the facilitators to the depth of content and content delivery truly I will say I learnt a lot and it was worth giving my time. It is an avenue and a school that can transform anyone who is willing to learn, gives you the opportunity to learn a lot of leading edge technologies and also deciding where to major. It was really an environment for empowering the future. Indeed I can tell you I am no longer where I used to be after an encounter with Technology School 1.0. Sessions were also recorded for review to enhance understanding of students. So far in the year 2022 one of my best decisions is being a participant of technology School 1.0.  Samuel Jaja Technology School 1.0 Participant.",
            school:"Website Dev",
            rating:4
        },

        {
          name: "Chibueze Nwokocha",
          review:
            "Well I would say that tech school 1.0 was wonderful. I learnt so much even though the timing was sometimes so off for me and my schedule.   The tutors actually took the time to make sure you understood to the tiniest of things, with express hands on tasks and assignment.",
            school:"Python",
            rating:4
        },
        {
          name: "Alexis Amachree",
          review:
            "Taking the Tech Origin Exclusive excel course for data analysis exposed me to the many uses and possibilities of the excel office tool. I am more confident in analyzing data and interpreting. I’m also positive to recommend the training to anyone interested, the course schedule and instructor is student friendly even for people with zero previous knowledge.",
            school:"Data Analysis",
            rating:5
        },

        {
          name: "Joyce Nkwocha",
          review:
            "It is a great and wonderful platform for learning with the tutors being as helpful and thorough as possible. One very commendable thing is the availability of class resources and it was a very wonderful opportunity which I'm very glad I grabbed",
            school:"Website Dev",
            rating:5
        },

        {
          name: "Emmanuel Asibor",
          review:
            "My experience with TSch 1.0. Was amazing..i got exposed to interesting applications and how to use them..the tutors where awesome ,always ready, patient and willing to explain and make sure everyone comprehends what is being thought. i am a proud data analyst and full stack developer because of them...thanks TSch 1.0,I am truly grateful for the knowledge impacted on me",
            school:"Data Analysis",
            rating:5
        },
      ],
    };
  },
};
</script>

<style scoped>
.rating {
  color: white;
}

.header {
  background-color: #F3F7FA;
  border-radius: 10px;
  width: fit-content;
  color: rgb(8, 103, 136);
  padding: 4px 10px;
  font-weight: bold;
  font-size: 10px;
}

.simple-flex {
  display: flex;
  align-items: center;
  column-gap: 10px;
  margin: 10px 5px
}

.space-between-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
}

.user-icon {
  color: white;
  font-size: 25px;
}

.primary-color {
  color: rgb(8, 103, 136);
}

.title-style {
  font-weight: bold;
  font-size: 17px;
  color: white;
}

#parr {
  color: rgb(8, 103, 136);
}

#parrr {
  color: rgb(8, 103, 136);
  transform: translateX(40%);
}

#carouselExampleInterval {
  background-color: rgb(8, 103, 136);
  border-radius: 3px;
}

#carouselExampleInterval:hover {
  cursor: pointer;
}

#orev {
  color: #f3f7fa;
  font-family: Nunito, sans-serif;
}

#orevv {
  color: #f3f7fa;
  margin-top: 10px;
  width: 97%;
}

.row {
  background-color: white;
  width: 100%;
}

.lised {
  align-items: center;
  transform: translateX(40%);
}

#review {
  margin-top: -20px;
}

#users {
  -webkit-border-radius: 60px;
  -moz-border-radius: 60px;
  border-radius: 60px;
  margin-top: 10px;
  color: white;
}

#bgg {
  background-color: rgb(8, 103, 136);
}

#pacc {
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 16px;
}

#par {
  color: rgb(8, 103, 136);
  font-family: Nunito, sans-serif;
  line-height: 27.2px;
}

.askin {
  height: 280px;
}

.accordion-button.collapsed {
  color: rgb(8, 103, 136);
  background: white;
}

.prog {
  line-height: 33px;
}

.programme {
  margin: auto;
  justify-content: space-between;
}

.faq {
  font-size: 25px;
  font-weight: 900;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

/* .accordion-button.collapsed::after {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
} */
@media only screen and (max-width: 600px) {
  #accordionFlushExample {
    margin-left: 0px;
  }
  #orevv {
    font-size: 13px;
    width: 80%;
  }

  .faq {
    font-size: 20px;
  }

  .accordion-item {
    width: 60%;
  }
}

@media only screen and (max-width: 480px) {
  #pacc {
    width: 50%;
  }

  .accordion-item {
    width: 70%;
  }

  #orevv {
    font-size: 13px;
    width: 95%;
  }
}

.accordion-button:focus {
  color: white;
  background-color: rgb(8, 103, 136);
  opacity: 0.7;
  border: 2px solid rgb(8, 103, 136);
  transition: 1.6s;
}

.accordion-body:enabled .accordion-button:enabled {
  color: white;
  background-color: rgb(8, 103, 136);
  opacity: 0.7;
  border: 2px solid rgb(8, 103, 136);
  transition: 1.6s;
}
</style>
