<template>
  <div class="loading-box">
    <img src="../assets/ALISU_logo.png" class="loader-logo"/>
    <p class="loader-title-tag">Welcome to TechOrigin</p>
    <p class="loader-subtitle-tag">Empowering you for the future</p>
  </div>
</template>
  <script>
export default {
  name: "LoaDer",
  data() {
    return {};
  },
  methods: {},
};
</script>
  
  <style scoped>
  .loader-logo{
    width:100px;
    height: 100px;
    animation:logo-anime infinite linear 2s 0s;
  }
  .loader-title-tag{
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 0px;
    color: rgb(8, 103, 136);
  }
  .loader-subtitle-tag{
    font-style: italic;
    font-size: 10px;
    margin: 0px;
  }
.loading-box {
  opacity: 0.9;
  background-color: white;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: rgb(8, 103, 136);
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes logo-anime {
  from{
    scale: 0.7;
  }
  to{
    rotate: 1;
  }
}

</style>