<template>
<Headingbar />
<div class="row" style="background-color: #F4F7F9; justify-content: center;">
<div class="col-md-5">
  <p class="h3 fw-bold pt-5 ps-2" id="par">LEARN MORE ABOUT CLOUD COMPUTING...</p>
  <p class="h6 fw-bold pt-2 ps-2 text-secondary">Cloud Computing is a network of remote servers hosted on the internet for storing data, retrieving data, running applications and managing them through configuration portals. The cloud provides a number of IT services such as servers, databases, software, virtual storage, and networking, among others. 
  </p>
  <router-link type="button" class="btn btn-outline mt-2 ms-2 mb-4"  to="/register">ENROLL NOW</router-link>
</div>
<div class="col-md-6">
  <img class="ms-2 my-1" src="../assets/Cloud-Computing.jpg" alt="Cloud_computing_image" style="height: 300px; width: 100%; border-radius: 6px;"/>
</div>
</div>
<svg viewBox="0 0 1000 100" fill="#F4F7F9">
  <path class="elementor-shape-fill" d="M761.9,44.1L643.1,27.2L333.8,98L0,3.8V0l1000,0v3.9"></path>
</svg>
<!-- course modules -->
<div class="row my-5" style="justify-content:center;">
  <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <img src="../assets/classroom.jpg" id="img"/>
  </div>
  <div class="col-md-5" style=" justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Module One</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Microsoft Excel For Data Analysis</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Advanced Microsoft Excel with VBA & Macros</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Power BI</p></li>
      <li><p class="h6 fw-bold text-dark pt-3">Prerequisite:<span class="h6 text-secondary fw-bold">&nbsp;None</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Fee:<span class="h6 text-secondary fw-bold">&nbsp; &#8358;50,000.00</span></p></li>
      <li><p class="h6 fw-bold text-dark">Class Schedule:<span class="h6 text-secondary fw-bold">&nbsp; 15 Classes (2hrs per class)</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Model:<span class="h6 text-secondary fw-bold">&nbsp; Virtual Private Class</span></p></li>
      <li><p class="h6 fw-bold text-dark">Assessment:<span class="h6 text-secondary fw-bold">&nbsp; Assignments, Projects</span></p></li>
      <li><p class="h6 fw-bold text-dark">Certification:<span class="h6 text-secondary fw-bold">&nbsp; Yes</span></p></li>
    </ul>
  </div>
</div>

<div class="row py-5" style="background-color: #F4F7F9; justify-content:center;">
  <div class="col-md-5" style=" justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Module Two</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">SQL</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Python for Data Analysis</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Introduction to Machine Learning with Python</p></li>
      <li><p class="h6 fw-bold text-dark pt-3">Prerequisite:<span class="h6 text-secondary fw-bold">&nbsp;Fundamentals of Excel</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Fee:<span class="h6 text-secondary fw-bold">&nbsp; &#8358;50,000.00</span></p></li>
      <li><p class="h6 fw-bold text-dark">Class Schedule:<span class="h6 text-secondary fw-bold">&nbsp; 15 Classes (2hrs per class)</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Model:<span class="h6 text-secondary fw-bold">&nbsp; Virtual Private Class</span></p></li>
      <li><p class="h6 fw-bold text-dark">Certification:<span class="h6 text-secondary fw-bold">&nbsp; Yes</span></p></li>
    </ul>
  </div>
  <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <img src="../assets/Booksevenimg.png" id="img"/>
  </div>
</div>

<div class="row py-5" style="justify-content:center;">
 <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <img src="../assets/TechOrigin_TSCH2Desgin.jpg" id="img"/>
  </div>
  <div class="col-md-5" style=" justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Module Three</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Mathematical Computations & Machine Learning</p></li>
      <li><p class="h6 fw-bold text-dark pt-3">Prerequisite:<span class="h6 text-secondary fw-bold">&nbsp;Mathematics and Statistics Background and Python</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Fee:<span class="h6 text-secondary fw-bold">&nbsp; &#8358;60,000.00</span></p></li>
      <li><p class="h6 fw-bold text-dark">Class Schedule:<span class="h6 text-secondary fw-bold">&nbsp; 10 Classes (2hrs per class)</span></p></li>
      <li><p class="h6 fw-bold text-dark">Course Model:<span class="h6 text-secondary fw-bold">&nbsp; Virtual Private Class</span></p></li>
      <li><p class="h6 fw-bold text-dark">Certification:<span class="h6 text-secondary fw-bold">&nbsp; Yes</span></p></li>
    </ul>
  </div>
</div>

<!-- Who should Enrol -->
<center><div class="row py-5" style="background-color: rgb(8,103,136); justify-content:center;">
    <center><p class="h3 fw-bold text-light py-2">WHO SHOULD ENROLL ?</p></center>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="white" class="bi bi-bar-chart-line-fill" viewBox="0 0 16 16">
      <path d="M11 2a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v12h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1v-3a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v3h1V7a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v7h1V2z"/>
      </svg>&nbsp; Gradutes in all fields</p>
  </div>
  <div class="col-md-6 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
      <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
      </svg>&nbsp; Entrepreneurs, Business managers, Digital marketers</p>
  </div>
  <div class="col-md-3 my-3">
    <p class="h6 fw-bold text-light"><svg width="26" height="26" fill="white" class="bi bi-lightbulb-fill" viewBox="0 0 16 16">
  <path d="M2 6a6 6 0 1 1 10.174 4.31c-.203.196-.359.4-.453.619l-.762 1.769A.5.5 0 0 1 10.5 13h-5a.5.5 0 0 1-.46-.302l-.761-1.77a1.964 1.964 0 0 0-.453-.618A5.984 5.984 0 0 1 2 6zm3 8.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1l-.224.447a1 1 0 0 1-.894.553H6.618a1 1 0 0 1-.894-.553L5.5 15a.5.5 0 0 1-.5-.5z"/>
</svg>&nbsp; IT Specialists</p>
  </div>
</div></center>

<div class="row py-5" style="background-color: #F4F7F9; justify-content:center;">
 <div class="col-md-5" style="overflow:hidden; justify-content:center;">
    <ul id="course">
      <li><p class="h4 my-2 fw-bold" id="par">Course Benefits</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Certificate after successful course completion</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Python for Data Analysis</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Introduction to Machine Learning with Python</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Introduction to Machine Learning with Python</p></li>
      <li class="listed"><svg width="16" height="16" fill="#136788" class="bi bi-pie-chart-fill" viewBox="0 0 16 16">
          <path d="M15.985 8.5H8.207l-5.5 5.5a8 8 0 0 0 13.277-5.5zM2 13.292A8 8 0 0 1 7.5.015v7.778l-5.5 5.5zM8.5.015V7.5h7.485A8.001 8.001 0 0 0 8.5.015z"/>
        </svg><p class="ps-3 h6 fw-bold">Mentorship</p></li>
    </ul>
  </div>
  <div class="col-md-5" style="overflow:hidden; justify-content:center;">
   <img src="../assets/Affiliate_P.png" id="img"/>
  </div>
</div>

<Base />
</template>

<script>
import Headingbar from '../components/Headingbar.vue';
import Base from '../components/Base.vue'
export default {
    name: "Clo_ud",
    components: {
        Headingbar,
        Base,
    }
}
</script>

<style scoped>
#par {
  color: rgb(8,103,136);
}
#course{
  list-style-type: none;
}
.btn-outline{
  color: rgb(8,103,136);
  border: 1.8px solid rgb(8,103,136);
  transition: 1s all ease;
}
.btn-outline:hover{
  background: rgb(8,103,136);
  color: white;
  z-index: -1;
  transition: all 1s ease;
}
#img {
  width: 100%;
  height: 300px;
  position: relative;
}
.listed {
  display:flex;
}
.bi-pie-chart-fill{
  transform: translateY(2px);
}
</style>