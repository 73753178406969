<template>
<Headingbar />
<Programs />
<Base />
</template>

<script>
import Headingbar from '../components/Headingbar.vue'
import Programs from '../components/Programs.vue'
import Base from '../components/Base.vue'
export default {
name: "Cour_ses",
components: {
  Headingbar,
  Programs,
  Base,
}
}
</script>

<style scoped>
.lised {
  list-style: circle;
    background-color: rgb(234, 238, 240);
}
#opti {
  background-color: rgb(248, 249, 250);
}
#prog{
  transform: translateX(4%);
}
</style>