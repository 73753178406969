import { createRouter, createWebHistory }
from "vue-router";
import store from "../shared/store";
import {
  LOADING_SPINNER_SHOW_MUTATION,
} from "../shared/storeconstants";

import Home from "../Views/Home.vue";
import About from "../Views/About.vue";
import Register from "../Views/Register.vue";
import RegisterShort from "../Views/RegisterShort.vue";
import RegisterPrivate from "../Views/RegisterPrivate.vue";
import Courses from "../Views/Courses.vue";
import DataAnalytics from "../Views/DataAnalytics.vue";
import Webdesign from "../Views/Webdesign.vue";
import Appdevop from "../Views/Appdevop.vue";
import UI_UX from "../Views/UI_UX.vue";
import Desktop from "../Views/Desktop.vue";
import Program from "../Views/Program.vue";
import Contact from "../Views/Contactus.vue";
import Blog from "../Views/Blog.vue";
import Cloud from "../Views/Cloud.vue";
import ReviewTab from '../Views/Review.vue'
import VerifyCert from "@/Views/VerifyCert.vue";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/review",
        name: "Review",
        component: ReviewTab,
    },
    {
        path: "/verify",
        name: "Verify",
        component: VerifyCert,
    },
    {
        path: "/about",
        name: "about",
        component: About,
    },
    {
        path: "/reg-tsch",
        name: "register",
        component: Register,
    },
    {
        path: "/reg-short",
        name: "registershort",
        component: RegisterShort,
    },
    {
        path: "/reg-private",
        name: "registerprivate",
        component: RegisterPrivate,
    },
    {
        path: "/Courses",
        name: "Courses",
        component: Courses,
    },
    {
        path: "/DataAnalytics",
        name: "DataAnalytics",
        component: DataAnalytics,
    },
    {
        path: "/Webdesign",
        name: "Webdesign",
        component: Webdesign,
    },
    {
        path: "/Appdevop",
        name: "Appdevop",
        component: Appdevop,
    },
    {
        path: "/UI_UX",
        name: "UI_UX",
        component: UI_UX,
    },
    {
        path: "/Desktop",
        name: "Desktop",
        component: Desktop,
    },
    {
        path: "/Program",
        name: "Program",
        component: Program,
    },
    {
        path: "/Contact",
        name: "Contact",
        component: Contact,
    },
    {
        path: "/Blog",
        name: "Blog",
        component: Blog,
    },
    {
        path: "/Cloud",
        name: "Cloud",
        component: Cloud,
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    async scrollBehavior() {
        
      store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await new Promise(r=>setTimeout(r,1000));
        store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
    //   document.getElementById('app').scrollIntoView({behavior:'smooth'});
   }
  
  })

export default router;