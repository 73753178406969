<template>
  <head>
    <link
      href="https://fonts.googleapis.com/css2?family=Nunito:wght@200&display=swap"
      rel="stylesheet"
    />
    <link
      href="//db.onlinewebfonts.com/c/9f94dc20bb2a09c15241d3a880b7ad01?family=Menlo"
      rel="stylesheet"
      type="text/css"
    />
  </head>
  <Headingbar />
  <div style="position: relative;">
    <div class="container" v-if="!successful">
    <div
      class="my-2"
      style="
        background-color: rgb(34, 116, 143);
        border-radius: 6px;
        padding: 10px 0px;
      "
    >
      <center>
        <h3 class="fw-bold text-light py-2">TECHORIGIN TECHNOLOGY DEVELOPMENT SCHOOL</h3>
      </center>
    </div>
    <div class="bg-light first">
      <p class="display-6 ps-3 py-3" style="font-size: 15px; font-weight: bold">
        Mobile Application Development & Data Analysis
      </p>
      <p class="h6 ps-3 pb-2">Welcome to TechOrigin Technology Development School (TTDS)! We're not just a school; we're your launchpad into the world of entrepreneurship and technical mastery. Join us to develop your skills, seize opportunities, and receive mentorship, paving the way for your success in the dynamic tech industry!
      </p>
      <p class="h6 ps-3 pb-2">
        Course Options
        <b class="p-text" style="color: rgb(34, 116, 143)">Complete Data Analysis & Fullstack Mobile Application Development</b>
      </p>
      <p class="h6 ps-3 pb-2">
        Commencement Date:
        <b class="p-text" style="color: rgb(34, 116, 143)">July 12th, 2024</b>
      </p>
      <p class="h6 ps-3 pb-2">
        Learning Schedule:
        <b class="p-text" style="color: rgb(34, 116, 143)">Friday, Saturday and Sunday </b>every week for 3 months
      </p>
      <p class="h6 ps-3 pb-2">The course also comes with a certificate of completion after successful completion of assignments, project work and internship</p>
      <p class="h6 ps-3 pb-2">
        Learning Schedule: Virtual School (Google Meet)
      </p>
      <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TechOrigin%20Flutter%2BFirebase%20Course%20Handout%202024.pdf?alt=media&token=4f3b1eb9-2bc9-4c60-bb08-b18b4e725a5d"
      target="_blank" class="download-brochure">Download Mobile Application  Brochure</a>
      <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TTDS%20DA%202024.pdf?alt=media&token=9a94e06a-acab-4a9a-96f0-22e55cae1e9a"
      target="_blank" class="download-brochure">Download Data Analysis Brochure</a>
      <!-- <p class="h6 ps-3 pb-2" v-if="registration_type == 'tsch'">
        Commencement: May 13th, 2024
      </p> -->
      <hr />
      <p class="h6 ps-3 pb-2">
        Contact us: 09031844175 or info@techorigin.alisutechnology.com
      </p>
    </div>
    <form id="addPost" @submit="onSubmit">
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Fullname <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your full name"
          type="text"
          id="name"
          v-model="name"
          name="name"
          placeholder="Your Name..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Email <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your Email"
          type="email"
          v-model="email"
          id="email"
          name="email"
          placeholder="Your Mail..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Phone Number <span style="color: red">*</span></label
        ><br />
        <input
          class="inputs ms-3"
          title="Input your Phone number"
          type="number"
          v-model="number"
          id="number"
          name="number"
          placeholder="Your Phone number..."
          required
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Gender <span style="color: red">*</span></label
        ><br />
        <div class="radio radio-danger ms-3 my-3">
          <input
            type="radio"
            name="survey1"
            id="Radios1"
            v-model="gender"
            value="Male"
            class="me-2"
          />
          <label>Male</label>
        </div>
        <div class="radio radio-danger ms-3 my-3">
          <input
            type="radio"
            name="survey1"
            id="Radios1"
            v-model="gender"
            value="Female"
            class="me-2"
          />
          <label>Female</label>
        </div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >Select Course Option <span style="color: red">*</span></label
        ><br />
        <div class="form-check ms-2">
          <input
            type="radio"
            name="org"
            id="FMACourse"
            v-model="organisation"
            value="Fullstack Mobile Application"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="FMACourse"
            >Fullstack Mobile Application</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="org"
            id="DACourse"
            v-model="organisation"
            value="Complete Data Analysis Course"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="DACourse"
            >Complete Data Analysis Course</label
          >
        </div>
      </div>
      <div class="bg-light first my-3 name" v-if="false">
        <label class="h6 ps-3 py-2"
          >Promo Code (If Any)</label
        ><br />
        <input
          class="inputs ms-3"
          title="Promo Code"
          type="text"
          placeholder="Enter Code..."
          v-model="promocode"
        />
        <div class="ms-3 mb-3 cover"><div class="effect"></div></div>
      </div>
      <div class="bg-light first my-3 name">
        <label class="h6 ps-3 py-2"
          >How did you know about TechOrigin?</label
        ><br />
        <div class="form-check ms-2">
          <input
            type="radio"
            name="informed"
            id="Radios"
            v-model="informed"
            value="Social Media (LinkedIn,Facebook or Twitter)"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Social Media (WhatsApp, LinkedIn,Facebook or Twitter)</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="informed"
            id="Radios"
            v-model="informed"
            value="Advert"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Advert</label
          >
        </div>
        <div class="form-check ms-2">
          <input
            type="radio"
            name="informed"
            id="Radios"
            v-model="informed"
            value="Hard Copy Flyer"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckDefault"
            >Hard Copy Flyer</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="informed"
            id="Radios"
            v-model="informed"
            value="Google Search"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Google Search</label
          >
        </div>
        <div class="form-check ms-2 my-3">
          <input
            type="radio"
            name="informed"
            id="Radios"
            v-model="informed"
            value="Others"
            class="me-2"
          />
          <label class="form-check-label ms-2" for="flexCheckChecked"
            >Others</label
          >
        </div>
      </div>
      <input
        type="submit"
        value="Submit"
        id="submit"
        class="mt-2"
        v-on:click="onSubmit()"
      />
    </form>
    <div>
      <center>
        <div><hr style="width: 100%" /></div>
      </center>
      <center>
        <p class="fw-light rc" id="par">
          Copyright © 2024 TechOrigin Technology Development Limited RC - 2022065
        </p>
      </center>
    </div>
    </div>
    <div style="position: absolute;width: 100%;background-color: white;display: flex;justify-content: center;top: 0px;left: 0px;align-items: center;flex-direction: column;height: 100vh;" v-if="successful">
      <img class="loader-logo" style="width:150px" src="../assets/ALISU_logo.png"/>
      <br>
      <p>Registration Completed, you will receive a call from a TTDS instructor shortly.</p>
      <p style="font-weight: bold;color:rgb(34, 116, 143)">Thank you for choosing TechOrigin</p>
      <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TechOrigin%20Flutter%2BFirebase%20Course%20Handout%202024.pdf?alt=media&token=4f3b1eb9-2bc9-4c60-bb08-b18b4e725a5d"
      target="_blank" class="download-brochure">Download Mobile Application Brochure</a>
      <a href="https://firebasestorage.googleapis.com/v0/b/techorigin-web.appspot.com/o/TTDS%20DA%202024.pdf?alt=media&token=9a94e06a-acab-4a9a-96f0-22e55cae1e9a"
      target="_blank" class="download-brochure">Download Data Analysis Brochure</a>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Headingbar from "../components/Headingbar.vue";
import store from "../shared/store";
import { LOADING_SPINNER_SHOW_MUTATION } from "../shared/storeconstants";
export default {
  name: "Regi_ster",
  components: {
    Headingbar,
  },
  data() {
    return {
      name: "",
      email: "",
      number: "",
      successful: false,
      gender: "",
      organisation: "",
      promocode: "",
      Informed: "",
      courseoption: "3 Weeks Intense Graphics Design Course",
      physical: false,
      virtual: false,
      registration_type: "Tsch",
      registration_title: "TechOrigin Technology Development School February 2024",
      showSnackbar: false,
      message: "",
      color: "success",
      icon: "mdi-check",
      timer: 3000,
    };
  },

  methods: {
    async onSubmit(e) {
      e.preventDefault();
      if (!this.name) {
        alert("Please Add a Name");
        return;
      }
      if (!this.number) {
        alert("Please Add Phone Number");
        return;
      }
      const NewInformation = {
        id: Math.floor(Math.random() * 100000),
        name: this.name,
        email: this.email,
        number: this.number,
        location: this.location,
        gender: this.gender,
        organisation: this.organisation,
        physical: this.physical,
        virtual: this.virtual,
        registrationtype: 'tsch',
        promocode: this.promocode,
        informed: this.informed,
      };
    store.commit(LOADING_SPINNER_SHOW_MUTATION, true);
      await axios
        .post(
          "https://script.google.com/macros/s/AKfycbw1908dwakW48mh-XfxEXcpk4GoLbCC_1bV8N56TzlBE8139FiHnwCVU_lpXgD7w126/exec?secretKey=TSch2024",
          JSON.stringify(NewInformation)
        )
        .then(function () {
          store.commit(LOADING_SPINNER_SHOW_MUTATION, false);
        });
        this.successful=true
    }
  },
  mounted() {
  },
};
</script>

<style scoped>
.download-brochure{
    color: rgb(8, 103, 136);
    border: none;
    border-radius: 5px;
    padding: 10px 10px;
    text-decoration: none;
    margin-left: 10px;
    cursor: pointer;
    background-color: transparent;
}
.download-brochure:hover{
    text-decoration: underline;
    color: rgb(8, 103, 136);
}
.first {
  box-shadow: 4px 3px 8px 1px #d4d3d3;
  -webkit-box-shadow: 4px 3px 8px 1px #d4d3d3;
  border: 1px solid rgb(182, 177, 177);
  margin-top: -22px;
  border-radius: 4px;
}
.inputs {
  border: none;
  outline: 0px solid transparent;
  background-color: rgb(248, 249, 250);
  width: 60%;
}

.effect {
  background-color: rgb(8, 103, 136);
  height: 1px;
  width: 0px;
  transition: width 0.8s;
}
.effect {
  transition-timing-function: linear;
}

.name:hover .effect {
  width: 100%;
}
.cover {
  height: 1px;
  width: 60%;
  background-color: rgb(219, 222, 223);
}
#submit {
  width: 20%;
  height: 54px;
  border-radius: 6px;
  color: #d4d3d3;
  font-weight: 600;
  background-color: rgb(8, 103, 136);
  transition: 0.9s;
  font-size: 20px;
  letter-spacing: 1px;
  margin-bottom: 40px;
  border: none;
  font-style: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}
#submit:hover {
  color: rgb(8, 103, 136);
  background-color: #d4d3d3;
}
#submit:focus {
  font-size: 20px;
  letter-spacing: 3px;
  width: 23%;
  height: 52px;
  transition: 0.6s;
  color: white;
  background-color: rgb(8, 103, 136);
}
@media only screen and (max-width: 600px) {
  #submit {
    font-size: 15px;
    width: 40%;
  }
}
</style>